import p1 from '../Assets/p1.png';
import p2 from '../Assets/p2.png';
import p3 from '../Assets/p3.png';
import suggestedP1 from '../Assets/prod1.svg';
import suggestedP2 from '../Assets/prod2.svg';
import suggestedP3 from '../Assets/prod3.svg';
import suggestedP4 from '../Assets/prod4.svg';
import { Currency, OutvioStatusCodes, SourceTrackingData } from '../Interfaces';
import { trackingToV2 } from './trackingData';

const EDIT_MODE_SOURCE_TRACKING_DATA: SourceTrackingData = {
  courier: 'UPS',
  currency: Currency.EUR,
  orderOtn: '',
  destination: 'Lisboa-PT',
  origin: 'Tallinn-EE',
  cancelingAllowed: true,
  destinationAddress: {
    address: 'Av. de Berna 46B',
    city: 'Lisboa',
    country: 'Portugal',
    postcode: '1050-220',
    comment: 'Leave it outside the door',
    name: 'John Doe',
    countryCode: 'PT',
    email: 'john@doe.test',
    phone: '+1 82739482',
    coordinates: {
      lng: -9.147704899999999,
      lat: 38.7067455,
    },
  },
  otn: '',
  trackingNumber: 'Z5A101Y6891234567',
  method: '24h Fast Shipping',
  vat: 9.4,
  id: 'Z5A101Y6891234567',
  originAddress: {
    address: 'Siidisaba 7',
    city: 'Tallinn',
    postcode: '11311',
    country: 'Estonia',
    countryCode: 'EE',
    contactName: 'Your Shop',
    contactPhone: '+372 12345678',
    coordinates: {
      lng: 24.725664,
      lat: 59.41371289999999,
    },
  },
  processDate: '2022-02-09T14:32:01.480Z',
  createdAt: '2022-02-07T14:56:53.480Z',
  dateTime: '2022-02-07T14:56:53.480Z',
  estimatedDeliverDate: '2022-07-17T00:00:00.000Z',
  total: 60.02,
  shippingPrice: 12,
  products: [
    {
      name: 'BRAND SHIRT',
      imgSrc: p1,
      variant: 'Red',
      amount: 1,
      price: 12.19,
      discountPrice: null,
    },
    {
      name: 'CLASSIC SNEAKERS',
      imgSrc: p2,
      variant: '',
      amount: 1,
      price: 30.3,
      discountPrice: null,
    },
    {
      name: 'Bang! Sweater',
      imgSrc: p3,
      variant: 'Black',
      amount: 1,
      price: 17.53,
      discountPrice: null,
    },
  ],
  canEditDeliveryAddress: true,
  recommendedProducts: [
    {
      title: 'Carhartt Jacket',
      imageUrl: suggestedP1,
      originalPrice: 179.0,
      discountedPrice: 149.0,
      currency: 'EUR',
      link: '#',
    },
    {
      title: 'Norse Jacket',
      imageUrl: suggestedP2,
      originalPrice: 99.0,
      currency: 'EUR',
      link: '#',
    },
    {
      title: 'Polar Puffer',
      imageUrl: suggestedP3,
      originalPrice: 119.0,
      currency: 'EUR',
      link: '#',
    },
    {
      title: 'Butter Windbreaker',
      imageUrl: suggestedP4,
      originalPrice: 69.0,
      discountedPrice: 59.0,
      currency: 'EUR',
      link: '#',
    },
  ],
};

const EDIT_MODE_SOURCE_STEPS = {
  steps: [
    {
      date: '10/02/2022 22:22',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.CREATED,
      coordinates: { lat: 59.41371289999999, lng: 24.725664 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '11/02/2022 10:12',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.PROCESSING,
      coordinates: { lat: 59.41371289999999, lng: 24.725664 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '12/02/2022 15:32',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.PICKED_UP,
      coordinates: { lat: 52.2296756, lng: 21.0122287 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '13/02/2022 11:50',
      location: 'Warsaw',
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.IN_TRANSIT,
      coordinates: { lat: 41.9027835, lng: 12.4963655 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '15/02/2022 11:50',
      location: 'Rome',
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.IN_TRANSIT,
      coordinates: { lat: 38.7222524, lng: -9.1393366 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
  ],
};

export const EDIT_MODE_TEST_DATA = trackingToV2(
  EDIT_MODE_SOURCE_TRACKING_DATA,
  EDIT_MODE_SOURCE_STEPS,
  true,
);
