import React, { FC, useContext, useEffect, useState } from 'react';

import { ChevronDownIcon } from 'icons';
import { OVCStoreDataContext } from 'return-portal-ui';
import { Editable, Slate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { getColorContrast } from 'ui';
import { Copy } from 'ui/atoms';

import useInitEditor from '../../../../Hooks/useInitEditor';
import { IQuestion } from '../../../../Interfaces';

interface Question {
  question: IQuestion;
}

const useQuestion = () => {
  const [isActive, setInternalIsActive] = useState(false);
  const setIsActive = (value: boolean) => setInternalIsActive(value);

  return { isActive, setIsActive };
};

const Question: FC<Question> = (props) => {
  const {
    question: { title, answer },
  } = props;
  const { isActive, setIsActive } = useQuestion();

  const {
    storeData: { portalV2 },
  } = useContext(OVCStoreDataContext);

  /**
   * This refresh key is needed to restart the answer in the Slate component.
   * Just passing it updated as prop doesn't update it, so the Slate component
   * needs to be entirely refreshed.
   */
  const [refreshKey, setRefreshKey] = useState(1);
  useEffect(() => {
    setRefreshKey(refreshKey + 1);
  }, [answer]);

  const noRotate = '!transition-transform duration-500 rotate-0';
  const rotate = '!transition-transform duration-500 rotate-180';
  const { editor } = useInitEditor();

  const slateData = { value: answer, initialValue: answer, editor } as any;

  const textColor = getColorContrast(portalV2?.colors?.header || '', false);

  return (
    <div
      className={twMerge(
        isActive && 'max-h-[2000px] transition-[max-height] duration-[1s] ease-[ease-in-out]',
      )}
    >
      <button
        onClick={() => setIsActive(!isActive)}
        className="flex items-center gap-4"
        type="button"
        style={{ color: textColor }}
      >
        <ChevronDownIcon
          className={twMerge('text-[24px] opacity-[70%]', isActive ? rotate : noRotate)}
        />
        <Copy as="span" className="text-left" noColor weight="semibold">
          {title}
        </Copy>
      </button>
      <div
        className="mt-2 transition-all duration-100 relative overflow-hidden"
        style={{
          opacity: isActive ? 0.7 : 0,
          height: !isActive ? '0px' : undefined,
          margin: !isActive ? 0 : undefined,
          visibility: !isActive ? 'hidden' : undefined,
          color: textColor,
        }}
      >
        <Slate key={refreshKey} {...slateData}>
          <Copy as="div" noColor className="ml-10 text-left" weight="regular">
            <Editable readOnly />
          </Copy>
        </Slate>
      </div>
    </div>
  );
};

export default Question;
