export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'TAGASI',
  'general.required': 'Kohustuslik',
  'general.PRODUCT_PACKAGE_BROKEN': 'Toote pakend purunenud',
  'general.PRODUCT_BROKEN': 'Toode purunenud',
  'general.PRODUCT_USED': 'Toode kasutamise märkidega',
  'general.PRODUCT_DIRTY': 'Toode määrdunud',
  'general.MISSING_PRODUCT_LABEL': 'Toote etiketid puudu',
  'general.PRODUCT_MISSING': 'Toode puudub',
  'general.OTHER': 'Muu',
  'general.credit-card': 'Pangakaart',
  'general.bank-transfer': 'Ülekanne',
  'general.cash-on-delivery': 'Sularahas kättetoimetamisel',
  'general.continue': 'Edasi',
  'general.CLOSED': 'SULETUD',
  'general.PARTIALLY_RECEIVED': 'OSALISELT KÄTTESAADUD',
  'general.RECEIVED': 'KÄTTESAADUD',
  'general.IN_TRANSIT': 'TEEL SIHTKOHTA',
  'general.comments': 'Märkmed',
  'general.return': 'Tagasta',
  'general.user-account': 'Kasutaja konto',
  'general.sign-out': 'Logi välja',
  'general.accept': 'Nõustu',
  'general.was-returned-on': 'Tagastus kuupäev',
  'general.product-cannot-returned': 'Seda toodet ei ole võimalik tagastada',
  'general.product-non-returnable':
    'Seda toodet ei ole võimalik tagastada. Palun võtke ühendust poe kasutajatoega kui soovite toodet tagastada',
  'general.can-return-it': 'Tagastatav kuni',
  'general.created': 'LOODUD',
  'general.shipped': 'SAADETUD',
  'general.in-transit': 'TEEL',
  'general.out-of-delivery': 'KÄTTETOIMETAMISEL',
  'general.delivered': 'KÄTTETOIMETATUD',
  'general.shipping-charges': 'TRANSPORDI KULUD',
  'general.order-details': 'Tellimuse detailid',
  'general.return-details': 'Tagastuse detailid',
  'general.no-orders-in-transit': 'Hetkel teel olevad tellimused puuduvad',
  'general.products-returned': 'Tagastatavad tooted',
  'general.select-motive': 'Vali põhjus',
  'general.why-want-return': 'Miks soovid antud toote tagastada?',
  'general.do-have-comments': 'Kas soovid lisada mõne kommentaari?',
  'general.enter-comment': 'Sisesta kommentaar',
  'general.do-want-attach-image': 'Kas soovid lisada pildi tootest?',
  'general.pickup-address': 'Korje aadress',
  'general.date-time-pickup': 'Korje kuupäev ja kellaaeg',
  'general.contact-phone-number': 'Kontakt telefoni number',
  'general.comments-courier': 'Sõnum kullerile',
  'general.confirm-address': 'Kinnitan pealekorje aadressi:',
  'general.quantity': 'Kogus',
  'general.length': 'Pikkus',
  'general.width': 'Laius',
  'general.height': 'Kõrgus',
  'general.identifier': 'Tunnus',
  'general.tracking-number': 'Pakinumber',
  'general.date-purchase': 'Ostu kuupäev',
  'general.date-time': 'Kuupäev aeg',
  'general.status': 'Staatus',
  'general.shipping-cost': 'Selle saadetise saatmiskulud',
  'general.free': 'Tasuta',
  'general.done': 'Valmis',
  'general.spanish': 'Español',
  'general.english': 'English',
  'returns.step.products.title': 'TAGASTATAVAD TOOTED',
  'returns.step.products.text': 'Vali tooted, mida soovid tagastada',
  'returns.step.method.title': 'TAGASTUSE MEETOD',
  'returns.step.method.text': 'Vali kuidas soovid tooted tagastada',
  'returns.step.instructions.title': 'JUHISED',
  'returns.step.instructions.text': 'Tagastuse ettevalmistamine',
  'returns.step.confirm.title': 'KINNITUS',
  'returns.step.confirm.text': 'Tagastus kinnitatud',
  'general.img-upload': 'Vali või tiri pildifail siia:',
  'retcart.packages.text': `Paki tooted, mis soovid tagastada
    {lineBreak}{lineBreak}MEIE SOOVITUSED:
    {lineBreak}- Kui võimalik kasuta seda sama pakendit, millega kaup sinuni jõudis. Taaskasutus on hea planeedile, ja tavaliselt ka kõige mugavam lahendus!
    {lineBreak}- Kui tagastad rohkem kui ühe toote, proovi kasutada võimalikult vähe pakendeid.
    {lineBreak}- Pea meeles, et ka õhk mis on paki sees tõstab saatmiskulusid! Kui võimalik ja ohutu, proovi kasutada võimalikult väikest pakendit. Nii vähenevad ka sinu saatmiskulud.`,
  'retcart.step2.heading':
    'KINNITA PAKENDI SUURUS (VAJUTA ‘LISA PAKEND’ KUI SAADAD TOOTED TAGASI MITME PAKIGA)',
  'retcart.pack.default-title.display': 'KASUTA SAMA PAKENDIT MILLEGA TOODE TULI:',
  'retcart.pack.custom-title.display': 'KASUTA OMA PAKENDIT',
  'retcart.pack.custom-title.edit': 'PAKI SUURUS (cm)',
  'retcart.pack.change-size': '(Muuda suurust)',
  'retcart.pack.remove': '(Eemalda)',
  'retcart.pack.add': 'LISA PAKK',
  'retcart.addrmodal.heading1': 'VALI EELNEVALT SALVESTATUD AADRESS',
  'retcart.addrmodal.heading2': 'VÕI LISA UUS AADRESS',
  'retcart.print.loading.heading': 'Sinu tagastust on töötlemisel...',
  'retcart.print.loading.note': 'Palun ära sulge seda akent kuni sinu tellimus on kinnitatud!',
  'landing.email-sent':
    'E-mail saadetud. Sisselogimiseks mine oma postkasti ja ava e-mailis olev link.',
  'general.status.IN_TRANSIT': 'TEEL',
  'general.status.DELIVERED': 'KOHAL',
  'general.status.DELIVERED_POST_OFFICE': 'KOHAL{lineBreak}PAKIPUNKTIS',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'KOHAL{lineBreak}PAKIPUNKTIS',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'OSALISELT KOHALETOIMETATUD',
  'general.status.PARTIALLY_DELIVERED.short': 'OSAL. KOHALETO.',
  'general.status.DELAYED': 'HILINEMISEGA',
  'general.status.RETURNED': 'TAGASTATUD',
  'general.status.DELETED': 'KUSTUTATUD',
  'general.status.RECEIVED': 'KÄTTESAADUD',
  'general.status.PARTIALLY_RECEIVED': 'OSALISELT KÄTTESAADUD',
  'general.status.PARTIALLY_RECEIVED.short': 'OSAL. KÄTTESA.',
  'general.status.CLOSED': 'SULETUD',
  'general.status.CREATED': 'LOODUD',
  'general.status.PICKING_QUEUE': 'PICKING QUEUE',
  'general.status.SHIPPING_QUEUE': 'SHIPPING QUEUE',
  'general.status.REFUNDED': 'RAHA TAGASTATUD',
  'general.status.SHIPMENT_PICKUP': 'SAADETISE KORJE',
  'general.status.SHIPPED': 'SAADETUD',
  'general.status.PICKUP': 'SAADETUD',
  'general.status.IN_TRANSIT_DELAYED': 'TEEL HILINEMISEGA',
  'general.status.EXCEPTION': 'INTSIDENT',
  'general.status.OUT_FOR_DELIVERY': 'KÄTTETOIMETAMISEL',
  'general.status.RETURNED_TO_ORIGIN': 'TAGASTATUD SAATJALE',
  'general.status.CANCELLED': 'TÜHISTATUD',
  'sidebar.orders-in-transit': 'TEEL OLEVAD TELLIMUSED',
  'sidebar.my-orders': 'MINU TELLIMUSED',
  'sidebar.returns': 'TAGASTUSED',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Kuupäev Aeg',
  'general.trackingbar.location': 'Asukoht',
  'general.trackingbar.status': 'Staatus',
  'general.trackingbar.detail': 'Info',
  'general.trackingbar.no-info': 'Teave ei ole kättesaadav.',
  'general.return-error.all-returned': 'Kõik tooted tagastatud.',
  'retcart.heading.methods': 'KUIDAS SOOVID OMA TOOTED TAGASTADA?',
  'retcart.method.toClientShop': 'VII TOOTED ISE MEIE POODI',
  'retcart.method.outvio': 'KASUTA TOODETE TAGASTAMISEKS MEIE KULLERIT',
  'retcart.method.ownCourier': 'SAADA TAGASTUS OMA KULLERIGA',
  'retcart.method.exchange': 'VAHETA TOODE TEISE VASTU',
  'retcart.inst.toClientShop.heading': 'KINNITA OMA TAGASTUS JA VII TOOTED MEIE POODI',
  'retcart.inst.toClientShop.text1':
    'TAGASTUSE KINNITAMISEKS PALUN Vajuta allolevat KINNITA nuppu:',
  'retcart.inst.toClientShop.text2':
    'Tagastuse kinnitamisel luuakse sulle Tagastusleht ja sa pead oma tagastuse meie poodi tooma hiljemalt {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Nimekirja kõigi meie poodide asukohaga leiad siin: {link}.',
  'retcart.inst.toClientShop.text3.link': 'poodide asukoha nimekiri',
  'general.confirm': 'kinnita',
  'retcart.inst.ownCourier.heading': 'KINNITA TAGASTUS JA POSTITA TOOTED OMA KULLERIGA',
  'retcart.inst.ownCourier.text1': 'TAGASTUSE KINNITAMISEKS PALUN Vajuta allolevat KINNITA nuppu:',
  'retcart.inst.ownCourier.text2':
    'Tagastuse kinnitamisel luuakse sulle Tagastusleht ja sa pead oma tagastuse meile saatma hiljemalt {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Aadress kuhu pead oma tagastuse saatma on:',
  'retcart.inst.ownCourier.text4':
    'PS: Sa võid kasutada ükskõik millist enda valitud kullerit ning kandma sellega kaasnevad saatmiskulud. Soovitame kasutada usaldusväärset jälgimisteenusega kullerit, et sinu tagastus turvaliselt kohale jõuaks.',
  'retcart.inst.exchange.heading': 'JÄTA MEILE VAJALIK INFO JA KINNITA VAHETUS',
  'retcart.inst.exchange.text1':
    'Millise toote, suuruse ja kogusega sooviksid sa tagastatava(d) toote(d) vahetada?',
  'retcart.inst.exchange.text2': 'Ära unusta lisada kogu vajalikku infot.',
  'retcart.inst.exchange.text.placeholder': 'Jäta oma vahetuse andmed siia.',
  'retcart.inst.exchange.text3': `Vahetuse kinnitamiseks, palun vajuta allolevat EDASI nuppu.
  
Info sinu vahetuse sooviga edastatakse meile e-mailna ja me võtame sinuga peatselt ühendust, et täpselt vahetuse detailides kokku leppida. Koopia sinu vahetuse soovist edastatakse ka sinu enda e-mailile.`,
  'retcart.confirm-return-terms':
    'Olen oma tagastuse üle kontrollinud ja nõustun tingimustega {terms}',
  'retcart.confirm-return-terms.terms': 'Kasutustingimused',
  'retcart.confirm.heading': 'TAGASTUS KINNITATUD',
  'retcart.confirm.text3': 'ET KÕIK SINU TAGASTUSEGA SUJUVALT LÄHEKS, PALUN:',
  'retcart.confirm.toClientShop.text4':
    '1. Prindi välja oma Tagastusleht või salvesta selle koopia.',
  'retcart.confirm.toClientShop.text5': '2. Vii oma tooted {fillerShop} poodi enne {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'ühte meie',
  'retcart.confirm.toClientShop.text6': '3. Võta oma Tagastusleht kindlasti kaasa.',
  'retcart.download-return-sheet': 'PRINDI TAGASTUSLEHT',
  'retcart.download-label': 'LAE ALLA TAGASTUSLEHT JA PAKIKAART',
  'retcart.confirm.ownCourier.text1':
    '1. Pringi välja Tagastusleht ja aseta see koos tagastatavate toodetega pakki.',
  'retcart.confirm.ownCourier.text2': '2. Saada oma tagastus usaldusväärse kulleriga aadressile:',
  'retcart.confirm.ownCourier.text3': '3. Saada pakk kindlasti välja enne {dueDate}.',
  'retcart.confirm.outvio.text1':
    'SINU TAGASTUS ON KINNITATUD, PALUN PRINDI VÄLJA TAGASTUSE PAKIKAART VAJUTADES ALLOLEVALE NUPULE',
  'retcart.confirm.outvio.text2': '(pakikaart on saadetud ka sinu e-mailile):',
  'retcart.confirm.outvio.text3': 'ET KÕIK KULLERI SAABUDES SUJUVALT LÄHEKS, PALUN::',
  'retcart.confirm.outvio.text4': '1. Prindi välja Tagastusleht ja lõika välja oma pakikaart.',
  'retcart.confirm.outvio.text5':
    '2. Aseta Tagastusleht koos tagastatavate toodetega paki sisse, sulge pakk ning kleebi pakikaart pakile.',
  'retcart.confirm.outvio.text6':
    '3. Oota kuni meie kuller sinu uksele koputab ja sinu tagastusele järgi tuleb!',
  'retcart.inst.headline.important': 'TÄHTIS:',
  'retcart.inst.hasPickup.noLabel': `1. Kontrollige oma tagastamise aadress ja tagastatavad tooteid üle. {LineBreak}
    2. Pärast nupu "KINNITA" vajutamist luuakse teile tagastusleht. {LineBreak}
    3. Pakendage tagastatavad esemed ja sulgege pakk hoolikalt. {fillerOptional}, võite lisada pakki ka tagastuslehe. {lineBreak}
    4. Peale nupu Kinnita vajutamist teavitame kullerit ja nad tulevad teie tagastusele järgi järgneva 2 tööpäeva jooksul. TÄHTIS! Kulleri pakikaardi väljaprintimine ei ole vajalik. Kuller toob ise vajaliku pakikaardi kaasa.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Soovi korral',
  'retcart.inst.hasPickup.byEmail': `1. Kontrolli üle oma tagastatavad kaubad ja aadress kuhu kuller tulema peab.{lineBreak}
      2. Kui vajutad nupule "KINNITA" luuakse sulle Tagastusleht ning me anname oma kullerile teada, et nad sinu juurde pakile järgi tuleksid. {fillerNotice}.{lineBreak}
      3. Seejärel aseta tagastatavad kaubad koos Tagastuslehega pakendi sisse ning sulge see turvaliselt. Kleebi pakile kulleri poolt saadetud pakikaart.{lineBreak}
      4. Meie kuller tuleb sinu pakile seejärel järgi 2 tööpäeva jooksul.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Pakikaart saadetakse teile e-maili aadressile ({email}) järgmise 2 tööpäeva jooksul.',
  'retcart.inst.hasPickup.provided': `1. Kontrollige oma tagastamise aadress ja tagastatavad tooteid üle.{lineBreak}
  2. Pärast nupu "KINNITA" vajutamist luuakse teile tagastusleht. Palun printige tagastusleht ning lõigage sealt välja kulleri pakikaart/pakikaardid.{lineBreak}
  3. Pakendage tagastatavad esemed, lisage pakki ka tagastusleht ja sulgege pakk hoolikalt. Kinnitage pakikaart paki välisküljele.{lineBreak}
  4. Kuller tuleb teie tagastusele järgi järgneva 2 tööpäeva jooksul.`,
  'retcart.inst.noPickup.byEmail': `1. Kontrolli tagastatavad tooted üle. {lineBreak}
      2. Kui vajutad nupule "KINNITA" luuakse sulle Tagastusleht. {fillerNotice}{lineBreak}
      3. Seejärel aseta tagastatavad kaubad koos Tagastuslehega pakendi sisse ning sulge see turvaliselt. Kleebi pakile kulleri poolt saadetud pakikaart.{lineBreak}
      4. Vii pakk 5 tööpäeva jooksul kulleri vastuvõtu punkti. Endale lähima vastuvõtupunkti leiad {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Kuller saadab pakikaardi sinu e-mailile ({email}) järgmise 2 tööpäeva jooksul.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'siit',
  // This diverges from the rest of texts
  'retcart.inst.noPickup.provided': `1. Kontrolli tagastatavad tooted üle. {lineBreak}
     2. Kui vajutad nupule "KINNITA" luuakse sulle Tagastusleht. Palun prindi see välja ja lõika välja pakikaardid (kui su tagastuses on rohkem kui 1 pakk, siis genereeritakse igale pakile ka eraldi pakikaart). PS: Kui sul ei ole printimisvõimalust, siis kirjuta pakile pakikaardil olev jälgimisnumber ning sisesta see pakiautomaadi juures, et avada õige kapiuks (alternatiivina võid skaneerida ka telefonist pakikaardi joonkoodi, et kapiuks automaatselt avada).{lineBreak}
     3. Seejärel aseta tagastatavad kaubad koos Tagastuslehega pakendi sisse ning sulge see turvaliselt. Kleebi pakikaart pakile.{lineBreak}
     4. Vii pakk 5 tööpäeva jooksul kulleri pakiautomaati/vastuvõtu punkti. Endale lähima pakiautomaadi/vastuvõtupunkti leiad {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'siit',
  'retcart.confirm.outvio.head-sheet':
    'SINU TAGASTUS ON KINNITATUD, PALUN SALVESTA OMA TAGASTUSLEHT VAJUTADES ALLOLEVALE NUPULE',
  'retcart.confirm.outvio.head-sheet.note': '(Tagastusleht on saadetud ka sinu e-mailile):',
  'retcart.confirm.outvio.head-label':
    'SINU TAGASTUS ON KINNITATUD, PALUN SALVESTA OMA PAKIKAART VAJUTADES ALLOLEVALE NUPULE',
  'retcart.confirm.outvio.head-label.note': '(Pakikaart on saadetud ka sinu e-mailile):',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'ET KÕIK KULLERI SAABUDES SUJUVALT LÄHEKS, PALUN:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Printige välja tagastusleht.{lineBreak}
    2. Pakendage tagastatavad esemed, lisage pakki ka tagastusleht ja sulgege pakk hoolikalt! Pakikaarti eraldi välja printida ei ole vaja.{lineBreak}
    3. Kuller tuleb teie tagastusele järgi järgneva 2 tööpäeva jooksul ja toob ise vajaliku pakikaardi kaasa.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: PAKIKAART SAADETAKSE SINU E-MAILILE JÄRGMISE 2 TÖÖPÄEVA JOOKSUL.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Peale kullerilt e-mailiga pakikaardi kättesaamist prindi see koos Tagastuslehega välja.{lineBreak}
      2. Aseta Tagastusleht koos tagastatavate toodetega pakki, sulge pakk ja kleebi pakikaart pakile.{lineBreak}
      3. Meie kuller tuleb sinu pakile järgi 2 tööpäeva jooksul.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Prindi Tagastusleht ja lõika pakikaart sealt välja.{lineBreak}
      2. Aseta Tagastusleht koos tagastatavate toodetega pakki, sulge pakk ja kleebi pakikaart pakile.{lineBreak}
      3. Meie kuller tuleb sinu pakile järgi 2 tööpäeva jooksul.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Peale kullerilt e-mailiga pakikaardi kättesaamist prindi see koos Tagastuslehega välja.{lineBreak}
      2. Aseta Tagastusleht koos tagastatavate toodetega pakki, sulge pakk ja kleebi pakikaart pakile.lineBreak}
      3. Vii pakk 5 tööpäeva jooksul kulleri vastuvõtu punkti. Endale lähima vastuvõtupunkti leiad {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'siit',
  // This text edit is only for ET language. It does diverge from the other texts, keep in mind when editing.
  'retcart.confirm.outvio.noPickup.provided': `1. Prindi Tagastusleht ja lõika pakikaart sealt välja.{lineBreak}
  2. Aseta Tagastusleht koos tagastatavate toodetega pakki, sulge pakk ja kleebi pakikaart pakile.{lineBreak}
  3. Vii pakk 5 tööpäeva jooksul kulleri pakiautomaati/vastuvõtu punkti. Endale lähima pakiautomaadi/vastuvõtupunkti leiad siit {filler}.{lineBreak}
  PS: Kui sul ei ole printimisvõimalust, siis kirjuta pakile pakikaardil olev jälgimisnumber ning sisesta see pakiautomaadi juures, et avada õige kapiuks (alternatiivina võid skaneerida ka telefonist pakikaardi joonkoodi, et kapiuks automaatselt avada).`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'siit',
  'general.retcart.restart.btn': 'Alusta tagastusega uuesti',
  'general.heading.refund.methods': 'KUIDAS SA SOOVID ET ME SULLE RAHA TAGASTAME?',
  'retcart.method.SAME_AS_PAYMENT': 'TAGASTUS ALGSELT KASUTATUD MAKSEMEETODILE',
  'retcart.method.CREDIT_IN_STORE': 'POE KREDIIT JÄRGMISTEKS OSTUDEKS',
  'retcart.confirm.exchange.heading': 'VAHETUSE SOOV KINNITATUD',
  'retcart.confirm.exchange.text1': 'SINU VAHETUSE SOOV ON EDASTATUD.',
  'retcart.confirm.exchange.text2':
    '(Koopia sinu vahetuse soovist on edastatud ka sinu e-mailile).',
  'retcart.confirm.exchange.text3':
    'Me võtame sinuga peatselt ühendust, et vahetuse detailides kokku leppida.',
  'retcart.confirm.exchange.text4': 'AITÄH!',
  'general.cancel': 'tühista',
  'general.retcart.init-restart':
    'Kui see probleem püsib, siis taaskäivita protsess klikkides SIIA',
  'retcart.return-method.toClientShop': 'VII POODI',
  'retcart.return-method.outvio': 'MEIE KULLERIGA',
  'retcart.return-method.ownCourier': 'OMA KULLERIGA',
  'retcart.return-method.exchange': 'VAHETA TOODE',
  'retcart.return-method.SAME_AS_PAYMENT': 'RAHA TAGASTUS',
  'retcart.return-method.CREDIT_IN_STORE': 'POE KREDIIT',
  'retcart.return-method.pickup': 'KULLER JÄRGI',
  'retcart.return-method.dropoff': 'VIIN PAKIPUNKTI',
  'general.heading.pickup.methods':
    'KAS SA TAHAD PAKI ISE PAKIPUNKTI VIIA VÕI KULLERI JÄRGI KUTSUDA?',
  'retcart.method.pickup': 'TAHAN ET KULLER TULEKS PAKILE KOJU JÄRGI',
  'retcart.method.dropoff': 'TAHAN ISE PAKI PAKIPUNKTI VIIA',
  'retcart.img-attach-desc': 'Lae üles pakendi, toote ja saatelehe pildid',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'TÄHTIS! ENNE TAGASTUSE PAKIPUNKTI VIIMIST, PALUN:',
  'retcart.title.pickup': 'korje kulleriga',
  'retcart.title.dropoff': 'vii pakipunkti',
  'general.tracking.manage-exception': 'Halda saadetist kulleri lehel',
  'general.error.id-ver-token-missing':
    'Kasutaja tuvastamiseks on vaja kinnitada kasutajasessioon. Tuvastuseks palun kontrolli oma e-maili.',
  'general.error.id-ver.token-missing':
    'Browseri viga. Palun kontrolli, et su browser oleks uuendatud või proovi kasutada teist browserit.',
  'general.error.fileupload.file-too-large': 'Maksimaalne faili suurus {size} MB',
  'general.error.fileupload.too-many-files': 'Maksimaalselt {number} faili',
  'general.error.fileupload.file-invalid-type': 'Vale faili tüüp',
  'general.error.fileupload.incorrect.size.max':
    'Pildi resolutsioon peab olema vähemalt {width} x {height}',
};
