export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'ATGAL',
  'general.required': 'Būtina',
  'general.PRODUCT_PACKAGE_BROKEN': 'Produkto pakuotė sugadinta',
  'general.PRODUCT_BROKEN': 'Produktas sugadintas',
  'general.PRODUCT_USED': 'Produktas panaudotas',
  'general.PRODUCT_DIRTY': 'Produktas purvinas',
  'general.MISSING_PRODUCT_LABEL': 'Trūksta produkto etiketės',
  'general.PRODUCT_MISSING': 'Nėra produkto',
  'general.OTHER': 'Kita',
  'general.credit-card': 'Kreditinė kortelė',
  'general.bank-transfer': 'Banko pavedimas',
  'general.cash-on-delivery': 'Mokėjimas pristačius',
  'general.continue': 'Tęsti',
  'general.Closed': 'UŽDARYTA',
  'general.PARTIALLY_RECEIVED': 'IŠ DALIES GAUTA',
  'general.RECEIVED': 'GAUTA',
  'general.IN_TRANSIT': 'TRANZITE',
  'general.comments': 'Komentarai',
  'general.return': 'Grįžti',
  'general.user-account': 'Vartotojo paskyra',
  'general.sign-out': 'Atsijungti',
  'general.accept': 'Priimti',
  'general.was-returned-on': 'Buvo grąžintas',
  'general.product-cannot-returned': 'Šio produkto negalima grąžinti',
  'general.product-non-returnable':
    'Šis produktas negali būti grąžinamas. Jei norite jį grąžinti, susisiekite su klientų aptarnavimo komanda ',
  'general.can-return-it': 'Galite grąžinti iki',
  'general.created': 'SUKURTA',
  'general.shipped': 'IŠSIŲSTA',
  'general.in-transit': 'TRANZITE',
  'general.out-of-delivery': 'IŠSIŲSTA',
  'general.delivered': 'PRISTATYTA',
  'general.shipping-charges': 'PRISTATYMO MOKESČIAI',
  'general.order-details': 'Užsakymo informacija',
  'general.return-details': 'Grąžinimo informacija',
  'general.no-orders-in-transit': 'Šiuo metu nėra gabenamų užsakymų',
  'general.products-returned': 'Grąžintini produktai',
  'general.select-motive': 'Pasirinkite priežastį',
  'general.why-want-return': 'Kodėl norite grąžinti šį produktą?',
  'general.do-have-comments': 'Ar turite komentarų',
  'general.enter-comment': 'Įveskite savo komentarą',
  'general.do-want-attach-image': 'Ar norite pridėti vaizdų?',
  'general.pickup-address': 'Paėmimo adresas',
  'general.date-time-pickup': 'Paėmimo data ir laikas',
  'general.contact-phone-number': 'Kontaktinis telefono numeris',
  'general.comments-courier': 'Komentaras kurjeriui',
  'general.confirm-address': 'Patvirtinu paėmimo adresą:',
  'general.quantity': 'Kiekis',
  'general.length': 'Ilgis',
  'general.width': 'Plotis',
  'general.height': 'Aukštis',
  'general.identifier': 'Identifikatorius',
  'general.tracking-number': 'Sekimo numeris',
  'general.date-purchase': 'Pirkimo data',
  'general.date-time': 'Data ir laikas',
  'general.status': 'Būklė',
  'general.shipping-cost': 'Šio grąžinimo išlaidos',
  'general.free': 'Nemokamai',
  'general.done': 'Atlikta',
  'general.spanish': 'Ispanų',
  'general.english': 'Anglų',
  'returns.step.products.title': 'PRODUKTŲ PASIRINKIMAS',
  'returns.step.products.text': 'Pasirinkite produktus, kuriuos norite grąžinti',
  'returns.step.method.title': 'GRĄŽINIMO BŪDAS',
  'returns.step.method.text': 'Pasirinkite grąžinimo būdą',
  'returns.step.instructions.title': 'INSTRUKCIJOS',
  'returns.step.instructions.text': 'Paruoškite savo grįžimą',
  'returns.step.confirm.title': 'PATVIRTINIMAS',
  'returns.step.confirm.text': 'Grąžinimas baigtas',
  'general.img-upload': 'Vilkite ir paleiskite arba paspauskite pridėti paveikslėlį',
  'retcart.packages.text': `Užpildykite pakuotes, kad  grįžtumėte
  {lineBreak} {lineBreak} MŪSŲ PATARIMAI:
  {lineBreak} - pabandykite pakartotinai naudoti tą pačią pakuotę, kuri buvo naudojama pristatant jūsų užsakymą. Tai naudinga planetai ir yra lengvai prieinama!
  {lineBreak} - jei grąžinate daugiau nei vieną prekę, pabandykite naudoti mažiau pakuočių.
  {lineBreak} - Atminkite, kad kai siunčiate daiktus, oras pakuotės viduje taip pat siunčiamas! Jei įmanoma ir saugu, pabandykite naudoti dėžę, kurioje būtų mažiau tuščios vietos. Tai sumažina jūsų grąžinimo išlaidas.`,
  'retcart.step2.heading':
    'PATVIRTINKITE PAKUOTĖS DYDĮ (Spustelėkite "PRIDĖTI PAKUOTĘ", JEI REIKIA DAUGIAU NEI VIENOS)',
  'retcart.pack.default-title.display': 'NAUDOTI PRISTATYMO PAKUOTĘ:',
  'retcart.pack.custom-title.display': 'NAUDOTI MUITINĮ PAKETĄ',
  'retcart.pack.custom-title.edit': 'NAUDOTI MUITINĮ PAKETĄ (dydis cm)',
  'retcart.pack.change-size': '(Pakeisti dydį)',
  'retcart.pack.remove': '(Pašalinti)',
  'retcart.pack.add': 'PRIDĖTI PAKUOTĘ',
  'retcart.addrmodal.heading1': 'PASIRINKITE BET KURĮ JŪSŲ IŠSAUGOTĄ ADRESĄ',
  'retcart.addrmodal.heading2': 'ARBA ĮVESKITE NAUJĄ ADRESĄ',
  'retcart.print.loading.heading': 'Mes apdorojame jūsų grąžinimą ...',
  'retcart.print.loading.note': 'Neuždarykite šio lango, kol neapdorosime jūsų užklausos!',
  'landing.email-sent':
    'El. laiškas sėkmingai išsiųstas, patvirtinkite savo el. pašto adresą per 15 minučių.',
  'general.status.IN_TRANSIT': 'TRANZITE',
  'general.status.DELIVERED': 'ĮTEIKTA',
  'general.status.DELIVERED_POST_OFFICE': 'ĮTEIKTA',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'PRISTATYTA Į{lineBreak}ATSIĖMIMO TAŠKĄ',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'DALINAI PRISTATYTA',
  'general.status.PARTIALLY_DELIVERED.short': 'DAL. PRISTATYTA',
  'general.status.DELAYED': 'ATIDĖTA',
  'general.status.RETURNED': 'GRĄŽINTA',
  'general.status.DELETED': 'IŠTRINTA',
  'general.status.RECEIVED': 'GAUTA',
  'general.status.PARTIALLY_RECEIVED': 'DALINAI GAUTA',
  'general.status.PARTIALLY_RECEIVED.short': 'DAL. GAUTA',
  'general.status.CLOSED': 'UŽDARYTA',
  'general.status.CREATED': 'SUKURTA',
  'general.status.PICKING_QUEUE': 'RINKIMO EILĖJE',
  'general.status.SHIPPING_QUEUE': 'PRISTATYMO EILĖ',
  'general.status.REFUNDED': 'GRĄŽINTA',
  'general.status.SHIPMENT_PICKUP': 'SIUNTIMO ATSIĖMIMAS',
  'general.status.SHIPPED': 'IŠSIŲSTA',
  'general.status.PICKUP': 'ATSIĖMIMAS',
  'general.status.IN_TRANSIT_DELAYED': 'VĖLUOJAMA TRANZITE',
  'general.status.EXCEPTION': 'IŠIMTIS',
  'general.status.OUT_FOR_DELIVERY': 'IŠSIŲSTA',
  'general.status.RETURNED_TO_ORIGIN': 'GRĮŽTA PAS SIUNTĖJĄ',
  'general.status.CANCELED': 'ATŠAUKTA',
  'sidebar.orders-in-transit': 'ORDERS IN TRANSIT',
  'sidebar.my-orders': 'MANO UŽSAKYMAI',
  'sidebar.returns': 'GRĄŽINIMAI',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'Išorinis kurjeris',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'Į parduotuvę',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Data ir laikas',
  'general.trackingbar.location': 'Vieta',
  'general.trackingbar.status': 'Būsena',
  'general.trackingbar.detail': 'Detalės',
  'general.trackingbar.no-info': 'Informacijos dar nėra.',
  'general.return-error.all-returned': 'Visi produktai buvo grąžinti.',
  'retcart.heading.methods': 'KAIP NORITE GRĄŽINTI PREKES?',
  'retcart.method.toClientShop': 'GRĄŽINKITE PREKES Į VIENĄ IŠ ŠALIA ESANČIŲ PARDUOTUVIŲ',
  'retcart.method.outvio': 'NAUDOKITE MŪSŲ KURJERĮ, KAD GRĄŽINTUMĖTE ATGAL MUMS',
  'retcart.method.ownCourier': 'ATLIKITE PREKĖS GRĄŽINIMĄ SU SAVO KURJERIU',
  'retcart.method.exchange': 'MAINYTI ARBA PAKEISTI PREKES VIETOJ GRĄŽINIMO',
  'retcart.inst.toClientShop.heading':
    'PATVIRTINKITE GRĄŽINIMĄ IR NUNEŠKITE PRODUKTUS Į ŠALIA ESANČIĄ PARDUOTUVĘ',
  'retcart.inst.toClientShop.text1': 'GRĄŽINIMO UŽBAIGIMUI paspauskite mygtuką PATVIRTINTI:',
  'retcart.inst.toClientShop.text2':
    'Jums bus sukurtas grąžinimo lapas, kurį turėsite nugabenti į vieną iš mūsų parduotuvių iki {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Visą mūsų parduotuvių sąrašą rasite čia: {link}.',
  'retcart.inst.toClientShop.text3.link': 'parduotuvių sąrašas',
  'general.confirm': 'patvirtinti',
  'retcart.inst.ownCourier.heading':
    'PATVIRTINKITE GRĄŽINIMĄ IR IŠSIŲSKITE PRODUKTUS SU JŪSŲ PASIRINKTU KURJERIU',
  'retcart.inst.ownCourier.text1': 'NORĖDAMI UŽBAIGTI GRĄŽINIMĄ paspauskite mygtuką PATVIRTINTI:',
  'retcart.inst.ownCourier.text2':
    'Jums bus sukurtas grąžinimo lapas ir turėsite užtikrinti, kad produktai bus išsiųsti iki {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Turėsite išsiųsti produktus adresu:',
  'retcart.inst.ownCourier.text4':
    'PS: Galite naudoti bet kurį jums tinkantį kurjerį, taip pat turėsite sumokėti už grąžinimo išlaidas. Mes rekomenduojame naudoti patikimą paslaugą su sekimo galimybe, kad žinotumėte, jog prekė mus pasiekė  saugiai.',
  'retcart.inst.exchange.heading': 'PATEIKITE INFORMACIJĄ IR PATVIRTINKITE PREKĖS PAKEITIMĄ',
  'retcart.inst.exchange.text1':
    'Su kokiu produktu, dydžiu ir kiekiu norėtumėte pakeisti dabartinį (-ius) produktą (-us)?',
  'retcart.inst.exchange.text2': 'Į pranešimą būtinai įtraukite visą reikalingą informaciją.',
  'retcart.inst.exchange.text.placeholder': 'Palikite mums savo prekės keitimo informaciją čia.',
  'retcart.inst.exchange.text3': `Norėdami užbaigti keitimą, spustelėkite Tęsti žemiau .',
  
Tada mums bus išsiųstas el. laiškas su jūsų prašymu pakeisti prekę ir mes susisieksime su jumis, kad susitartume dėl keitimosi detalių. El. laiško kopija taip pat bus išsiųsta jūsų el. pašto adresu.`,
  'retcart.confirm-return-terms': 'Peržiūrėjau grąžinimą ir sutinku su {terms}',
  'retcart.confirm-return-terms.terms': 'Terminai ir sąlygos',
  'retcart.confirm.heading': 'PREKĖS GRĄŽINIMAS BAIGTAS',
  'retcart.confirm.text3': 'NORINT ĮSITIKINTI, AR PREKĖS GRĄŽINIMAS VYKSTA SKLANDŽIAI:',
  'retcart.confirm.toClientShop.text4':
    '1. Atsispausdinkite grąžinimo lapą arba išsaugokite elektroninę kopiją, kurią išsiuntėme, į jūsų el. paštą.',
  'retcart.confirm.toClientShop.text5': '2. Nuneškite produktus į {fillerShop} iki {dueDate}.',
  'retcart.confirm.toClientShop.text5.fillerShop': 'a shop near you',
  'retcart.confirm.toClientShop.text6':
    '3. Įsitikinkite, kad su savimi turite savo grąžinimo lapą.',
  'retcart.download-return-sheet': 'ATSISIŲSTI GRĄŽINIMO LAPĄ',
  'retcart.download-label': 'ATSISIŲSTI ETIKETĘ  IR GRĄŽINIMO LAPĄ',
  'retcart.confirm.ownCourier.text1':
    '1. Atsispausdinkite grąžinimo lapą ir įdėkite jį į pakuotę kartu su grąžinamais produktais.',
  'retcart.confirm.ownCourier.text2':
    '2. Išsiųskite savo siuntą su patikimu sekamu kurjeriu, kurį pasirinkote:',
  'retcart.confirm.ownCourier.text3':
    '3. Būtinai išsiųskite savo grąžinamą produktą iki {dueDate}.',
  'retcart.confirm.outvio.text1':
    'JŪSŲ GRĄŽINIMAS SĖKMINGAI APDOROTAS, ATSISIŲSKITE SAVO SIUNTIMO ETIKETĘ SPUSTELĖDAMI ŽEMIAU ESANTĮ MYGTUKĄ',
  'retcart.confirm.outvio.text2': '(etiketė taip pat išsiųsta į jūsų el. paštą):',
  'retcart.confirm.outvio.text3': 'KAD ATVYKUS KURJERIUI VISKAS VYKTŲ SKLANDŽIAI, PRAŠOME:',
  'retcart.confirm.outvio.text4':
    '1. Atsispausdinkite grąžinimo lapą ir iškirpkite siuntimo etiketę.',
  'retcart.confirm.outvio.text5':
    '2. Įdėkite grąžinimo lapą į pakuotę ir užklijuokite etiketę ant sandariai uždarytos siuntos pakuotės.',
  'retcart.confirm.outvio.text6':
    '3. Atsipalaiduokite ir laukite, kol kurjeris atsiims jūsų grąžinamą siuntą!',
  'retcart.inst.headline.important': 'SVARBU:',
  'retcart.inst.hasPickup.noLabel': `1. Grąžindami, peržiūrėkite savo atsiėmimo adresą ir produktus.{lineBreak}
    2. Spustelėjus "CONFIRM", bus sukurtas grąžinimo lapas.{lineBreak}
    3. Įdėkite grąžintinus daiktus į pakuotę ir uždarykite dėžutę. {fillerOptionally}, Taip pat dėžutėje galite pridėti ir grąžinimo lapą.{lineBreak}
    4. Kai spustelėsite Patvirtinti, pranešime kurjeriui ir jis atvyks atsiimti jūsų grąžinimo per kitas 2 darbo dienas. SVARBU! Jums nereikia spausdinti jokios siuntimo etiketės. Kurjeris turės ją atvykęs atsiimti siuntos.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Pasirinktinai',
  'retcart.inst.hasPickup.byEmail': `1. Grąžindami peržiūrėkite savo atsiėmimo adresą ir produktus. {lineBreak}
  2. Spustelėjus „PATVIRTINTI“, bus sukurtas grąžinimo lapas ir siuntimo užklausa bus išsiųsta mūsų kurjeriui. {fillerNotice}. {lineBreak}
  3. Gavę siuntimo etiketę, įdėkite grąžinimo lapą į pakuotę, uždarykite ir priklijuokite etiketę ant pakuotės. {lineBreak}
  4. Mūsų kurjeris atsiims jūsų grąžinimą per kitas 2 darbo dienas.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Siuntos etiketę gausite el. paštu({email}) per kitas 2 darbo dienas.',
  'retcart.inst.hasPickup.provided': `1. Grąžindami, peržiūrėkite savo atsiėmimo adresą ir produktus.{lineBreak}
    2. Spustelėjus "CONFIRM", bus sukurtas grąžinimo lapas. Prašome jį atspausdinti ir iškirpti siuntos etiketę kiekvienam grąžinimo paketui.{lineBreak}
    3. Įdėkite grąžintinas prekes į pakuotę kartu su grąžinimo lapu, užklijuokite siuntos etiketę ir uždarykite dėžutę.{lineBreak}
    4. Kurjeris atvyks atsiimti jūsų grąžinimo per kitas 2 darbo dienas.`,
  'retcart.inst.noPickup.byEmail': `1. Peržiūrėkite grąžinamas prekes. {lineBreak}
  2. Spustelėjus „PATVIRTINTI“, bus sukurtas grąžinimo lapas. {fillerNotice} {lineBreak}
  3. Grąžinimo siuntimo etiketę gausite el. paštu. Gavę siuntimo etiketę, įdėkite grąžinimo lapą į pakuotę, uždarykite ir priklijuokite siuntimo etiketę ant pakuotės. {lineBreak}
  4. Per 5 darbo dienas pakuotę (-es) turėsite nugabenti į kurjerio pasiėmimo vietą, esančią arčiausiai jūsų. Norėdami rasti artimiausią paėmimo punktą, apsilankykite kurjerių tinklalapyje {fillerCourierHomepage}. `,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    'Per kitas 2 dienas pristatymo etiketę gausite el. pašto adresu ({email})',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'čia',
  'retcart.inst.noPickup.provided': `1. Peržiūrėkite grąžinamas prekes. {lineBreak}
      2. Spustelėjus „PATVIRTINTI“, bus sukurtas grąžinimo lapas. Atsispausdinkite ir iškirpkite kiekvienos siuntos siuntimo etiketę.{lineBreak}
      3. Įdėkite grąžinamas prekes į pakuotę kartu su grąžinimo lapu, užklijuokite siuntimo etiketę ir sandariai uždarykite.{lineBreak}
      4. Pakuotę turėsite nugabenti į kurjerio pasiėmimo vietą, esančią arčiausiai jūsų. Norėdami rasti artimiausią paėmimo punktą, apsilankykite kurjerių tinklalapyje{fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'čia',
  'retcart.confirm.outvio.head-sheet':
    'JŪSŲ GRĄŽINIMAS SĖKMINGAI APDOROTAS, ATSISIŲSKITE SAVO GRĄŽINIMO LAPĄ SPUSTELĖDAMI ŽEMIAU ESANTĮ MYGTUKĄ',
  'retcart.confirm.outvio.head-sheet.note':
    '(Grąžinimo lapas taip pat išsiųstas į jūsų el. paštą):',
  'retcart.confirm.outvio.head-label':
    'JŪSŲ GRĄŽINIMAS SĖKMINGAI APDOROTAS, ATSISIŲSKITE SAVO SIUNTIMO ETIKETĘ SPUSTELĖDAMI ŽEMIAU ESANTĮ MYGTUKĄ',
  'retcart.confirm.outvio.head-label.note': '(etiketė taip pat išsiųsta į jūsų el. paštą):',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'KAD ATVYKUS KURJERIUI VISKAS VYKTŲ SKLANDŽIAI, PRAŠOME:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Atsispausdinkite grąžinimo lapą (nebūtina).{lineBreak}
    2.Įdėkite grąžinimo lapą į saugiai uždarytą pakuotę. SIUNTOS ETIKETĖS NEREIKALINGOS!{lineBreak}
    3. Kurjeris atvyks atsiimti jūsų grąžinimo per kitas 2 darbo dienas ir turės su savimi siuntimo etiketę, kad galėtumėte atlikti prekės grąžinimą.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS.: SIUNTIMO ETIKETĖ BUS IŠSIŲSTA Į JŪSŲ EL. PAŠTĄ PER KELIAS KITAS DIENAS.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Gavę siuntimo etiketę į savo el. paštą, atsispausdinkite ją kartu su grąžinimo lapu. {lineBreak}
  2. Įdėkite grąžinimo lapą į pakuotę, uždarykite ir užklijuokite siuntimo etiketę. {lineBreak}
  3. Kurjeris atvyks atsiimti jūsų grąžinimo per kitas 2 darbo dienas.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Atsispausdinkite grąžinimo lapą ir nupjaukite siuntimo etiketę.{lineBreak}
      2. Įdėkite grąžinimo lapą į pakuotę, uždarykite ir užklijuokite siuntimo etiketę.{lineBreak}
      3. Kurjeris atvyks atsiimti jūsų grąžinimo per kitas 2 darbo dienas.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Gavę siuntimo etiketę į savo el. paštą, atspausdinkite ją kartu su grąžinimo lapu.{lineBreak}
      2. Įdėkite grąžinimo lapą į pakuotę, uždarykite ir užklijuokite siuntimo etiketę.{lineBreak}
      3. Nuvežkite savo paketą į kurjerio paėmimo punktą, esantį šalia jūsų. Norėdami rasti artimiausią paėmimo punktą, apsilankykite kurjerių tinklalapyje {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'čia',
  'retcart.confirm.outvio.noPickup.provided': `1. Atsispausdinkite grąžinimo lapą ir nupjaukite siuntimo etiketę.{lineBreak}
      2. Įdėkite grąžinimo lapą į pakuotę, uždarykite ir užklijuokite siuntimo etiketę.{lineBreak}
      3. Nuvežkite savo paketą į kurjerio paėmimo punktą, esantį šalia jūsų. Norėdami rasti artimiausią paėmimo punktą, apsilankykite kurjerių tinklalapyje {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'čia',
  'general.retcart.restart.btn': 'Pradėti grąžinimo procesą iš naujo',
  'general.heading.refund.methods': 'KAIP NORITE ATGAUTI PINIGUS?',
  'retcart.method.SAME_AS_PAYMENT': 'GRĄŽINKITE MANO PRADINIU MOKĖJIMO METODU',
  'retcart.method.CREDIT_IN_STORE': 'GAUKITE PINIGUS ATEITIES PIRKIMAMS',
  'retcart.confirm.exchange.heading': 'GRĄŽINIMO PRAŠYMAS ĮVYKDYTAS',
  'retcart.confirm.exchange.text1': 'JŪSŲ PRAŠYMAS GRĄŽINIMUI GAUTAS SĖKMINGAI.',
  'retcart.confirm.exchange.text2':
    '(Jūsų prašymo prekės pakeitimui kopija taip pat išsiųsta jūsų el. pašto adresu).',
  'retcart.confirm.exchange.text3':
    'Netrukus susisieksime su jumis, norėdami pranešti apie jūsų prekės pakeitimo informaciją.',
  'retcart.confirm.exchange.text4': 'AČIŪ!',
  'general.cancel': 'atšaukti',
  'general.retcart.init-restart':
    'Jei ši problema išlieka, paleiskite procesą iš naujo spustelėdami ČIA',
  'retcart.return-method.toClientShop': 'PARDUOTUVĖJE',
  'retcart.return-method.outvio': 'MŪSŲ KURJERIU',
  'retcart.return-method.ownCourier': 'SAVO KURJERIU',
  'retcart.return-method.exchange': 'MAINYTI / PAKEISTI',
  'retcart.return-method.SAME_AS_PAYMENT': 'PINIGŲ GRĄŽINIMAS',
  'retcart.return-method.CREDIT_IN_STORE': 'MOKĖJIMAS PARDUOTUVĖJE',
  'retcart.return-method.pickup': 'ATSIIMANT',
  'retcart.return-method.dropoff': 'GRĄŽINTI',
  'general.heading.pickup.methods':
    'AR NORITE PATYS GRĄŽINTI SAVO SIUNTĄ AR KAD JĄ PAIMTŲ KURJERIS?',
  'retcart.method.pickup': 'NORIU, KAD KURJERIS PAIMTŲ SIUNTĄ IŠ MANO NAMŲ ADRESO',
  'retcart.method.dropoff': 'NORIU GRĄŽINTI SIUNTĄ Į KURJERIO ATSIĖMIMO TAŠKĄ',
  'retcart.img-attach-desc': 'Įkelkite pakuotės, produkto ir siuntimo etiketės nuotraukas',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'SVARBU! PRIEŠ NUNEŠANT SIUNTĄ Į SURINKIMO TAŠKĄ, PRAŠOME JŪSŲ:',
  'retcart.title.pickup': 'Atsiėmimas',
  'retcart.title.dropoff': 'Pristatymas',
  'general.tracking.manage-exception': 'Tvarkyti išimtį kurjerio svetainėje',
  'general.error.id-ver-token-missing':
    'Autentifikavimui reikalingas prieigos raktas. Patikrinkite, atsiųstą elektroninį laišką su autentifikavimo instrukcijomis.',
  'general.error.id-ver.token-missing':
    'Inicijavimo klaida. Įsitikinkite, jog jūsų naudojama naršyklė yra atnaujinta.',
  'general.error.fileupload.file-too-large': 'Didžiausias failo dydis {size} MB',
  'general.error.fileupload.too-many-files': 'Daugiausia {number} failas(-ai)',
  'general.error.fileupload.file-invalid-type': 'Netinkamas failo tipas',
  'general.error.fileupload.incorrect.size.max':
    'Didžiausia galima paveikslėlio raiška yra {width} {height}',
};
