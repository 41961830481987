import { useCallback, useEffect } from 'react';

import { getJetTokenPayComet, getPayCometJetId, insertCreditCard } from 'data-layer';
import { useIntl } from 'localization';
import { PaymentSystem } from 'return-portal-ui/old-ui/types/StoreSettings';

import { GAWrapper } from '../../../utils/GAWrapper';

type CardDataProps = {
  holderName: string;
  number: string;
  cvc: string;
  expiryYear: string;
  expiryMonth: string;
  generationtime: string;
};

const useActivateNewCard = (paymentSystem: PaymentSystem | undefined) => {
  const { locale } = useIntl();

  const accountLanguage = locale.slice(0, 2);

  useEffect(() => {
    if (paymentSystem === 'adyen') {
      // ======= Load adyen SDK ===============================

      if (!document.getElementById('adyen-sdk')) {
        const scriptSrc = import.meta.env.VITE_OUTVIO_ADYEN_SDK;
        if (scriptSrc) {
          const script = document.createElement('script');

          script.id = 'adyen-sdk';
          script.src = scriptSrc;
          script.async = true;

          document.body.appendChild(script);
        }
      }
    }
  }, [paymentSystem]);

  return useCallback(
    async (cardData: CardDataProps) => {
      // ======= ADYEN ===============================
      if (paymentSystem === 'adyen') {
        if (!(window as any).adyen) {
          return 'Adyen failed to initialize';
        }

        const cseInstance = (window as any).adyen.createEncryption(
          process.env.OUTVIO_ADYEN_API_KEY,
          {},
        );

        let encryptedCartData = null;
        try {
          encryptedCartData = cseInstance.encrypt(cardData);

          if (encryptedCartData === false) {
            throw new Error('CSE returned false');
          }
        } catch (error: any) {
          return 'Error with encryption.';
        }

        try {
          await insertCreditCard({
            holderName: cardData.holderName,
            number: cardData.number,
            expiryYear: `20${cardData.expiryYear}`,
            expiryMonth: cardData.expiryMonth,
            activate: true,
            'adyen-encrypted-data': encryptedCartData,
          });

          GAWrapper.setValue({
            event: 'credit_card_attached',
          });
        } catch (error) {
          return (error as any)?.message || error?.toString();
        }
      }

      // ======= PAYCOMET ===============================
      if (paymentSystem === 'payComet') {
        try {
          const paycometData = await getPayCometJetId();

          const formData = new FormData();

          const formDataString = JSON.stringify({
            apiID: paycometData.jetId,
            method: 'iframe',
            datos: [
              { key: 'language', val: accountLanguage },
              { key: 'paNumber', val: cardData.number },
              { key: 'cvc2', val: cardData.cvc },
              { key: 'cardHolderName', val: cardData.holderName },
              { key: 'dateMonth', val: cardData.expiryMonth },
              { key: 'dateYear', val: cardData.expiryYear },
            ],
          });

          formData.append('param', formDataString);
          const payCometData = await getJetTokenPayComet(formData);

          await insertCreditCard({
            holderName: cardData.holderName,
            number: cardData.number,
            expiryYear: `20${cardData.expiryYear}`,
            expiryMonth: cardData.expiryMonth,
            activate: true,
            jetToken: payCometData.paytpvToken,
          });

          GAWrapper.setValue({
            event: 'credit_card_attached',
          });
        } catch (error) {
          return (error as any)?.message || error?.toString();
        }
      }
    },
    [paymentSystem, accountLanguage],
  );
};

export { useActivateNewCard };
