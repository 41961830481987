import React, { ButtonHTMLAttributes, useContext } from 'react';

import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { getColorContrast } from 'ui';
import { Button } from 'ui/atoms';

import { getCalculatedBrightnessFromHex } from '../utils';
import OVCStoreDataContext from './OVCStoreDataContext';

export interface IOVCButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  showLoader?: boolean;
  icon?: React.ReactNode;
  theme?: 'primary' | 'secondary' | 'alert';
  disabled?: boolean;
  onClick?: () => void;
}

const OVCButton = ({
  theme = 'primary',
  children,
  className,
  disabled,
  showLoader = false,
  icon = null,
  onClick,
  ...rest
}: IOVCButton) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const textColor = getColorContrast(storeData.portalV2?.colors.btnBg || '', false);
  const borderColor = getCalculatedBrightnessFromHex(
    storeData.portalV2?.colors?.secondaryBg || '',
    2,
  );

  const styling = match(theme)
    .with('primary', () => ({
      backgroundColor: storeData.portalV2?.colors.btnBg,
      color: textColor,
    }))
    .with('secondary', () => ({
      backgroundColor: storeData.portalV2?.colors.secondaryBg,
      color: storeData.portalV2?.colors.btnBg,
      borderWidth: '1px',
      borderColor: borderColor,
    }))
    .with('alert', () => ({
      backgroundColor: '#FF6A00',
      color: '#FFFFFF',
      borderWidth: '0px',
    }))
    .exhaustive();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      onClickIcon={onClick}
      icon={
        <div className="flex items-center justify-center w-full" style={{ color: styling.color }}>
          {icon}
        </div>
      }
      loading={showLoader}
      style={{
        ...styling,
        boxShadow: '0 0 #0000',
        padding: children ? '' : '6px 2px 6px 6px',
      }}
      className={twMerge('shadow-none', className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default OVCButton;
