import { PaymentPlanProps } from '../hooks/usePaymentFormHooks';

export const PLAN_INFO_KEY = 'plan-info';
type PAYMENT_STATUS_KEYS = 'plan-upgrade-in-progress' | 'sms-in-progress';

const saveToLocalStoragePlanInfo = (info: PaymentPlanProps) => {
  localStorage.setItem(PLAN_INFO_KEY, JSON.stringify(info));
};
const getFromLocalStoragePlanInfo = () => {
  const result = localStorage.getItem(PLAN_INFO_KEY);

  return result ? (JSON.parse(result) as PaymentPlanProps) : undefined;
};
const cleanUpLocalStoragePlanInfo = () => {
  localStorage.removeItem(PLAN_INFO_KEY);
};

const saveToLocalStoragePaymentStatus = (key: PAYMENT_STATUS_KEYS) => {
  localStorage.setItem(key, 'true');
};
const getFromLocalStoragePaymentStatus = (key: PAYMENT_STATUS_KEYS) => {
  const result = localStorage.getItem(key);

  return result ? result === 'true' : false;
};

const cleanUpLocalStoragePaymentStatus = (key: PAYMENT_STATUS_KEYS) => {
  localStorage.removeItem(key);
};

export {
  saveToLocalStoragePlanInfo,
  getFromLocalStoragePlanInfo,
  cleanUpLocalStoragePlanInfo,
  saveToLocalStoragePaymentStatus,
  getFromLocalStoragePaymentStatus,
  cleanUpLocalStoragePaymentStatus,
};
