import React from 'react';

import { IAppSettings, ISingleRegionPortalSettings, ITrackingData } from '../Interfaces';
import { EDIT_MODE_TABS } from '../Interfaces/IEditModal';
import { Features } from '../Interfaces/IFeatures';
import { SourceDestinationAddress } from '../Interfaces/ITrackingData';
import { Products } from '../Interfaces/Product';

export interface TrackingPageContextProps {
  trackingData?: ITrackingData;
  appSettings: IAppSettings;
  portalSettings: ISingleRegionPortalSettings;
  isError: boolean;
  handleEditButton?(tab: EDIT_MODE_TABS): void;
  handleRate?(rating: number): void;
  features: Features;
  handleChangeDeliveryAddress?(
    address: Partial<SourceDestinationAddress>,
  ): Promise<{ success: true } | { success: false; errors: string[] }>;
  isSplitOrder?: boolean;
  isDeleted?: boolean;
  othersProducts?: Products;
  packagesHeaderComponent?: React.ReactNode;
  shipmentHeaderComponent?: React.ReactNode;
  cancelingAllowed: boolean;
}

const TrackingPageContext = React.createContext({} as TrackingPageContextProps);

export default TrackingPageContext;
