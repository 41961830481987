import { memo } from 'react';

import { MastercardIcon } from 'icons';

import VisaIcon from '../../../../../../apps/frontend/src/assets/images/visa.svg';

const SupportedCardsIconsHeader = memo(() => (
  <div className="w-full flex justify-end gap-2">
    {[
      { icon: () => <MastercardIcon className="text-[24px]" /> },
      { icon: () => <img className="h-4" src={VisaIcon} alt="Visa" /> },
    ].map(({ icon: Icon }, index) => (
      <div
        key={`payment-card-icon-${index}`}
        className="rounded-[10px] w-[68px] h-9 flex items-center justify-center bg-white border border-v2blueGray-100"
      >
        <Icon />
      </div>
    ))}
  </div>
));

export default SupportedCardsIconsHeader;
