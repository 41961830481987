import { useCallback, useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubscriptionPlan } from 'app-types/core';
import { useGetPaymentMethods, useGetUser } from 'data-layer';
import { useIntl } from 'localization';
import { match } from 'ts-pattern';

import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';
import { PricingPlanProps } from '../../PricingPage/types/pricingTypes';
import { PaymentFormProps } from '../types/PaymentTypes';
import {
  cleanUpLocalStoragePlanInfo,
  getFromLocalStoragePlanInfo,
  saveToLocalStoragePaymentStatus,
  saveToLocalStoragePlanInfo,
} from '../utils/localStoragePaymentInfo';
import {
  paymentValidationSchema,
  shopifyPaymentValidationSchema,
} from '../validation/paymentValidationSchema';
import { useActivateNewCard } from './useActivateNewCard';
import { ProcessPaymentProps, usePaymentProcessing } from './usePaymentProcessing';
import { useShopifyPaymentProcessing } from './useShopifyPaymentProcessing';

type PaymentForm = {
  isShopifyBilling: boolean;
  deskOnly: boolean;
  selectedPlan: PricingPlanProps;
  onPaymentSuccess: () => void;
  updateSubscriptionInfo: () => Promise<void>;
  onChangeSubmit: (isSubmitting: boolean) => void;
  onClose: () => void;
  isOnTrial: boolean;
};

export type PaymentPlanProps = {
  plan: SubscriptionPlan;
  desk: boolean;
  deskOnly: boolean;
  paymentMethod: string;
};

const usePaymentForm = ({
  isShopifyBilling,
  deskOnly,
  selectedPlan,
  updateSubscriptionInfo,
  onChangeSubmit,
  onClose,
  isOnTrial,
}: PaymentForm) => {
  const [errorMessage, setErrorMessage] = useState('');

  const contextData = useContext(SubscriptionContext);

  const { data: userData } = useGetUser();
  const { data: paymentMethods } = useGetPaymentMethods();

  const paymentSystem = userData?.storeSettings.paymentSystem;

  const activateNewCard = useActivateNewCard(paymentSystem);
  const processPayment = usePaymentProcessing();
  const processShopifyPayment = useShopifyPaymentProcessing();

  const intl = useIntl();

  const validationSchema = match<boolean>(true)
    .with(isShopifyBilling, () => shopifyPaymentValidationSchema(intl))
    .otherwise(() => paymentValidationSchema(intl));

  const formMethods = useForm<PaymentFormProps>({
    resolver: yupResolver(validationSchema as any) as any,
  });

  const { handleSubmit, reset, watch, getValues } = formMethods;

  const { paymentMethod: paymentMethodFormField } = watch();

  useEffect(() => {
    reset({
      acceptedTerms: false,
      paymentMethod: '',
      cvc: '',
      desk: selectedPlan.isDeskIncluded || deskOnly,
      expiry: '',
      holderName: '',
      number: '',
      plan: selectedPlan.plan,
    });
  }, []);

  useEffect(() => {
    setErrorMessage('');
  }, [paymentMethodFormField]);

  const onSubmitForm = useCallback(
    async (formData: PaymentFormProps) => {
      onChangeSubmit(true);

      const expSplit = formData.expiry.split('/');
      const trimmedCardNumber = formData.number.trim().replace(/\s/g, '');

      const dataForPaymentProcessing: PaymentPlanProps = {
        paymentMethod: formData.paymentMethod, // "new" or CardID
        desk: formData.desk,
        deskOnly,
        plan: formData.plan,
      };

      saveToLocalStoragePlanInfo(dataForPaymentProcessing);

      if (formData.paymentMethod === 'new') {
        const cardData = {
          holderName: formData.holderName,
          number: trimmedCardNumber,
          cvc: formData.cvc,
          expiryYear: expSplit[1],
          expiryMonth: expSplit[0],
          generationtime: new Date().toISOString(),
        };

        // After that, we are waiting for card activation and a response on sockets
        const errorKey = await activateNewCard(cardData);

        if (errorKey) {
          setErrorMessage(errorKey);
          onChangeSubmit(false);
          cleanUpLocalStoragePlanInfo();
        }
      } else {
        await upgradeProcess(dataForPaymentProcessing);
      }
    },
    [paymentSystem, selectedPlan, paymentMethods],
  );

  const onShopifySubmit = useCallback(
    async (formData: PaymentFormProps) => {
      onChangeSubmit(true);

      const errorKey = await processShopifyPayment(formData.plan);

      if (errorKey) {
        setErrorMessage(errorMessage);
      }
    },
    [selectedPlan],
  );

  const upgradeProcess = useCallback(
    async (paymentPlanProps: PaymentPlanProps) => {
      saveToLocalStoragePaymentStatus('plan-upgrade-in-progress');

      const needToChangePlan =
        contextData?.subscription.plan !== paymentPlanProps.plan ||
        !contextData?.subscription.isActive;

      const dataForPaymentProcessing: ProcessPaymentProps = {
        paymentMethod: paymentPlanProps.paymentMethod, // "new" or cardId
        selectedPlan: {
          ...selectedPlan,
          plan: paymentPlanProps.plan,
          isDeskIncluded: paymentPlanProps.desk,
        },
        allRegisteredCards: paymentMethods?.list || [],
        isOnTrial,
      };

      if (paymentPlanProps.deskOnly) {
        dataForPaymentProcessing.selectedPlan.isDeskOnly = true;
      }

      const errorKey = await processPayment(dataForPaymentProcessing);

      cleanUpLocalStoragePlanInfo();

      if (!errorKey) {
        if (!needToChangePlan && paymentPlanProps.deskOnly) {
          onClose();
        } else {
          await updateSubscriptionInfo();
        }
      } else {
        setErrorMessage(errorKey);
        onChangeSubmit(false);
      }
    },
    [paymentSystem, selectedPlan, paymentMethods],
  );

  useEffect(() => {
    // Getting data from FORM if no 3dsecure or from Localstorage if 3dsecure is activated
    const localStorageFormData = getFromLocalStoragePlanInfo();

    if (contextData?.planProgress?.cardActivated === true && localStorageFormData) {
      const formData = getValues();
      const dataForPaymentProcessing: PaymentPlanProps = {
        paymentMethod: formData.paymentMethod, // "new" or CardID
        desk: formData.desk,
        deskOnly,
        plan: formData.plan,
      };

      if (formData.paymentMethod) {
        upgradeProcess(dataForPaymentProcessing);
      } else {
        onChangeSubmit(true);

        reset({
          acceptedTerms: true,
          desk: localStorageFormData.desk,
          plan: localStorageFormData.plan,
        });
        upgradeProcess(localStorageFormData);
      }
    }

    if (contextData?.planProgress?.cardActivated === false) {
      cleanUpLocalStoragePlanInfo();
      setErrorMessage(intl.formatMessage({ id: 'UPGRADE_PAYMENT_FAILED' }));
      onChangeSubmit(false);
    }
  }, [contextData?.planProgress?.cardActivated]);

  useEffect(() => {
    if (contextData?.planProgress?.error) {
      setErrorMessage(intl.formatMessage({ id: 'UPGRADE_PAYMENT_FAILED' }));
      cleanUpLocalStoragePlanInfo();
      onChangeSubmit(false);
    }
  }, [contextData?.planProgress?.upgradeFinished]);

  useEffect(() => {
    if (contextData?.shopifyProgress?.url && !contextData?.shopifyProgress?.error) {
      window.location.assign(contextData?.shopifyProgress.url);
    } else if (contextData?.shopifyProgress?.error) {
      setErrorMessage(
        contextData?.shopifyProgress?.error || intl.formatMessage({ id: 'UPGRADE_PAYMENT_FAILED' }),
      );
      onChangeSubmit(false);
    }
  }, [contextData?.shopifyProgress?.url, contextData?.shopifyProgress?.error]);

  return {
    errorMessage,
    onSubmit: handleSubmit(onSubmitForm),
    onShopifySubmit: handleSubmit(onShopifySubmit),
    formMethods,
  };
};

export default usePaymentForm;
