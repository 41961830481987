export default {
  'general.lang.ca': 'Catalão',
  'general.lang.de': 'Alemão',
  'general.lang.ee': 'Estoniano',
  'general.lang.en': 'Inglês',
  'general.lang.es': 'Espanhol',
  'general.lang.et': 'Estoniano',
  'general.lang.fi': 'Finlandês',
  'general.lang.fr': 'Francês',
  'general.lang.it': 'Italiano',
  'general.lang.lt': 'Lituano',
  'general.lang.lv': 'Letão',
  'general.lang.pl': 'Polaco',
  'general.lang.pt': 'Português',
  'general.back': 'VOLTAR',
  'general.required': 'Obrigatório',
  'general.PRODUCT_PACKAGE_BROKEN': 'Embalagem danificada',
  'general.PRODUCT_BROKEN': 'Produto danificado',
  'general.PRODUCT_USED': 'Produto usado',
  'general.PRODUCT_DIRTY': 'Produto sujo',
  'general.WRONG_PRODUCT_RETURNED': 'Produto errado devolvido',
  'general.MISSING_PRODUCT_LABEL': 'Produto sem etiqueta',
  'general.PRODUCT_MISSING': 'Produto em falta',
  'general.OTHER': 'Outro',
  'general.credit-card': 'Cartão de crédito',
  'general.bank-transfer': 'Transferência bancária',
  'general.cash-on-delivery': 'Envio à cobrança',
  'general.continue': 'Continuar',
  'general.CLOSED': 'FECHADO',
  'general.PARTIALLY_RECEIVED': 'PARCIALMENTE RECEBIDO',
  'general.RECEIVED': 'RECEBIDO',
  'general.IN_TRANSIT': 'EM TRÂNSITO',
  'general.comments': 'Comentários',
  'general.return': 'Voltar',
  'general.user-account': 'Utilizador',
  'general.sign-out': 'Sair',
  'general.accept': 'Aceitar',
  'general.was-returned-on': 'Foi devolvido em',
  'general.product-cannot-returned': 'Este produto não pode ser devolvido',
  'general.product-non-returnable':
    'Este produto não pode ser devolvido. Por favor contacta o apoio ao cliente para mais informações',
  'general.can-return-it': 'Podes devolvê-lo até',
  'general.created': 'CRIADO',
  'general.shipped': 'ENVIADO',
  'general.in-transit': 'EM TRÂNSITO',
  'general.out-of-delivery': 'EM DISTRIBUIÇÃO',
  'general.delivered': 'ENTREGUE',
  'general.shipping-charges': 'PORTES DE ENVIO',
  'general.order-details': 'Detalhes da encomenda',
  'general.return-details': 'Detalhes da devolução',
  'general.no-orders-in-transit': 'Nenhuma encomenda em trânsito de momento',
  'general.products-returned': 'Produtos a devolver',
  'general.select-motive': 'Seleciona o motivo da devolução',
  'general.why-want-return': 'Porque motivo queres devolver este produto?',
  'general.do-have-comments': 'Queres acrescentar algum comentário?',
  'general.enter-comment': 'Escreve um comentário',
  'general.do-want-attach-image': 'Queres juntar alguma imagem?',
  'general.pickup-address': 'Endereço de recolha',
  'general.date-time-pickup': 'Data e hora da recolha',
  'general.contact-phone-number': 'Contacto telefónico',
  'general.comments-courier': 'Deixar indicações à transportadora',
  'general.confirm-address': 'Confirmo o endereço de recolha:',
  'general.quantity': 'Quantidade',
  'general.length': 'Comprimento',
  'general.width': 'Largura',
  'general.height': 'Altura',
  'general.identifier': 'Identificador',
  'general.tracking-number': 'Número de tracking',
  'general.date-purchase': 'Data da compra',
  'general.date-time': 'Hora da compra',
  'general.status': 'Estado',
  'general.shipping-cost': 'Portes de Envio para esta devolução',
  'general.free': 'Grátis',
  'general.done': 'Efetuado',
  'general.spanish': 'Spanish',
  'general.english': 'English',
  'returns.step.products.title': 'SELECIONAR O PRODUTO',
  'returns.step.products.text': 'Seleciona os produtos a devolver',
  'returns.step.method.title': 'Método de devolução',
  'returns.step.method.text': 'Escolhe o método para a devolução',
  'returns.step.instructions.title': 'INSTRUÇÕES',
  'returns.step.instructions.text': 'Prepara a tua devolução',
  'returns.step.confirm.title': 'CONFIRMAÇÃO',
  'returns.step.confirm.text': 'Devolução efetuada',
  'general.img-upload': 'Arrasta e solta ou clica para adicionar uma imagem',
  'retcart.packages.text': `Preenche os detalhes da embalagem para finalizares a tua devolução
    {lineBreak}{lineBreak}AS NOSSAS SUGESTÕES:
    {lineBreak}- Reutiliza a embalagem em que te foi entregue o produto. É bom para o ambiente e a caixa está mesmo à mão!
    {lineBreak}- Se devolveres mais do que um produto, tenta utilizar o mínimo número possível de embalagens.
    {lineBreak}- Lembra-te, quando envias uma encomenda, o ar dentro da caixa também é enviado! Sempre que possível, usa uma embalagem que minimize o espaço vazio. Assim, minimizas também o custo dos portes de envio.`,
  'retcart.step2.heading':
    'INDICA A DIMENSÃO DA TUA EMBALAGEM (CLICA ‘ADICIONAR EMBALAGEM’ SE PRECISARES DE MAIS DO QUE UMA)',
  'retcart.pack.default-title.display': 'USAR A EMBALAGEM ENTREGUE:',
  'retcart.pack.custom-title.display': 'USAR OUTRA EMBALAGEM',
  'retcart.pack.custom-title.edit': 'USAR OUTRA EMBALAGEM (dimensão em cm)',
  'retcart.pack.change-size': '(Alterar dimensão)',
  'retcart.pack.remove': '(Remover)',
  'retcart.pack.add': 'ADICIONAR EMBALAGEM',
  'retcart.addrmodal.heading1': 'SELECIONA UM DOS ENDEREÇOS GUARDADOS',
  'retcart.addrmodal.heading2': 'ADICIONA UM NOVO ENDEREÇO',
  'retcart.print.loading.heading': 'Estamos a processar a tua devolução...',
  'retcart.print.loading.note': 'Não feches esta janela até que a tua devolução seja processada!',
  'landing.email-sent':
    'Email enviado com successo, por favor verifica a tua caixa de entrada dentro dos próximos 15 minutes.',
  'general.status.IN_TRANSIT': 'EM TRÂNSITO',
  'general.status.DELIVERED': 'ENTREGUE',
  'general.status.DELIVERED_POST_OFFICE': 'ENTREGUE',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'ENTREGUE NO{lineBreak}PONTO DE RECOLHA',
  'general.status.DELIVERED_TO_SHOP': 'ENTREGUE PARA{lineBreak}LOJA',
  'general.status.PARTIALLY_DELIVERED': 'PARCIALMENTE ENTREGUE',
  'general.status.PARTIALLY_DELIVERED.short': 'ENTREGUE PARC.',
  'general.status.DELAYED': 'ATRASADAS',
  'general.status.RETURNED': 'DEVOLVIDA',
  'general.status.DELETED': 'ELIMINADA',
  'general.status.RECEIVED': 'RECEBIDA',
  'general.status.PARTIALLY_RECEIVED': 'PARCIALMENTE RECEBIDA',
  'general.status.PARTIALLY_RECEIVED.short': 'RECEBIDA PARC.',
  'general.status.CLOSED': 'FECHADA',
  'general.status.CREATED': 'CRIADA',
  'general.status.PICKING_QUEUE': 'A PREPARAR A RECOLHA',
  'general.status.SHIPPING_QUEUE': 'A PREPARAR O ENVIO',
  'general.status.REFUNDED': 'REEMBOLSADA',
  'general.status.SHIPMENT_PICKUP': 'À ESPERA DE RECOLHA',
  'general.status.SHIPPED': 'ENVIADA',
  'general.status.PICKUP': 'RECOLHIDA',
  'general.status.IN_TRANSIT_DELAYED': 'EM TRÂNSITO, ATRASADA',
  'general.status.EXCEPTION': 'EXCEÇÃO',
  'general.status.OUT_FOR_DELIVERY': 'EM DISTRIBUIÇÃO',
  'general.status.RETURNED_TO_ORIGIN': 'DEVOLVIDA À ORIGEM',
  'general.status.CANCELLED': 'CANCELADA',
  'sidebar.orders-in-transit': 'ENCOMENDAS EM TRÂNSITO',
  'sidebar.my-orders': 'OS MEUS PEDIDOS',
  'sidebar.returns': 'DEVOLUÇÕES',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'Transportadora externa',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Transportadora interna',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Hora',
  'general.trackingbar.location': 'Localização',
  'general.trackingbar.status': 'Estado',
  'general.trackingbar.detail': 'Detalhes',
  'general.trackingbar.no-info':
    'Ainda não há informação de tracking disponível para a tua encomenda. Pode demorar alguma horas até que ela seja atualizada. Por favor tenta mais tarde.',
  'general.return-error.all-returned': 'Todos os produtos foram devolvidos.',
  'retcart.heading.methods': 'COMO QUERES DEVOLVER OS PRODUTOS?',
  'retcart.method.toClientShop': 'ENTREGA OS PRODUTOS NUMA DAS NOSSAS LOJAS PERTO DE TI',
  'retcart.method.outvio': 'UTILIZA A NOSSA TRANSPORTADORA PARA ENVIARES A TUA DEVOLUÇÃO',
  'retcart.method.ownCourier': 'ENVIA-NOS A TUA DEVOLUÇÃO COM UMA TRANSPORTADORA À TUA ESCOLHA',
  'retcart.method.exchange': 'TROCA OU SUBSTITUI OS PRODUTOS EM VEZ DE OS DEVOLVERES',
  'retcart.inst.toClientShop.heading':
    'CONFIRMA A TUA DEVOLUÇÃO E ENTREGA OS PRODUTOS NUMA LOJA PERTO DE TI',
  'retcart.inst.toClientShop.text1':
    'Para concluires a tua devolução, por favor clica em CONFIRMAR:',
  'retcart.inst.toClientShop.text2':
    'Vai ser criada uma Folha de Devolução que terás de apresentar, junto com os produtos a devolver, numa das nossas lojas até ao dia {dueDate}.',
  'retcart.inst.toClientShop.text3':
    'Podes consultar a lista completa das nossas lojas aqui: {link}.',
  'retcart.inst.toClientShop.text3.link': 'localização das nossas lojas',
  'general.confirm': 'confirmar',
  'retcart.inst.ownCourier.heading':
    'CONFIRMA A TUA DEVOLUÇÃO E ENVIA OS PRODUTOS COM UMA TRANSPORTADORA À TUA ESCOLHA',
  'retcart.inst.ownCourier.text1': 'Para concluires a tua devolução, por favor clica CONFIRMAR:',
  'retcart.inst.ownCourier.text2':
    'Vai ser criada uma Folha de Devolução. Os produtos devem ser enviados antes do dia {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Por favor envia os produtos para:',
  'retcart.inst.ownCourier.text4':
    'PS: Podes utilizar qualquer transportadora à tua escolha, mas os portes de envio são por tua conta. Recomendamos que utilizes uma transportadora de confiança, e com serviço de tracking, para garantir que a encomenda chega até nós em segurança.',
  'retcart.inst.exchange.heading': 'INFORMA-NOS DE TODOS OS DETALHES E CONFIRMA A TUA TROCA',
  'retcart.inst.exchange.text1':
    'Por qual produto, tamanho e quantidade pretendes trocar o(s) produto(s)?',
  'retcart.inst.exchange.text2':
    'Não te esqueças de incluir toda a informação necessária na tua mensagem.',
  'retcart.inst.exchange.text.placeholder': 'Indica aqui os detalhes da tua troca.',
  'retcart.inst.exchange.text3': `Para concluires a tua troca, por favor clica em CONFIRMAR.

Ser-nos-á enviado um email com o pedido de troca. Assim que o recebermos, entraremos em contacto contigo para acertar os detalhes da troca. Receberás também uma copia desse email.`,
  'retcart.confirm-return-terms': 'Confirmo que revi a minha devolução e que aceito os {terms}',
  'retcart.confirm-return-terms.terms': 'Termos e Condições',
  'retcart.confirm.heading': 'DEVOLUÇÃO CONCLUÍDA',
  'retcart.confirm.text3':
    'PARA GARANTIR QUE TUDO CORRE CONFORME PLANEADO COM A TUA DEVOLUÇÃO, POR FAVOR SEGUE OS SEGUINTES PASSOS:',
  'retcart.confirm.toClientShop.text4':
    '1. Imprime a Folha de Devolução ou guarda no computador a cópia digital que te enviamos por email.',
  'retcart.confirm.toClientShop.text5':
    '2. Entrega os produtos em {fillerShop} antes do dia {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'uma loja perto de ti',
  'retcart.confirm.toClientShop.text6':
    '3. Não te esqueças de trazer a Folha de Devolução contigo.',
  'retcart.download-return-sheet': 'TRANSFERIR A FOLHA DE DEVOLUÇÃO',
  'retcart.download-label': 'TRANSFERIR A ETIQUETA DE ENVIO E A FOLHA DE DEVOLUÇÃO',
  'retcart.confirm.ownCourier.text1':
    '1. Imprime a Folha de Devolução e coloca-a na embalagem junto com os produtos a devolver.',
  'retcart.confirm.ownCourier.text2':
    '2. Envia-nos a tua devolução com uma transportadora de confiança à tua escolha:',
  'retcart.confirm.ownCourier.text3': '3. Envia-nos os produtos a devolver antes do dia {dueDate}.',
  'retcart.confirm.outvio.text1':
    'A TUA DEVOLUÇÃO FOI PROCESSADA COM SUCESSO, POR FAVOR CLICA NO BOTÃO ABAIXO PARA TRANSFERIRES A ETIQUETA DE ENVIO',
  'retcart.confirm.outvio.text2': '(a etiqueta foi enviada também para o teu email):',
  'retcart.confirm.outvio.text3':
    'PARA GARANTIR QUE CORRE TUDO COMO PLANEADO QUANDO A TRANSPORTADORA CHEGAR, POR FAVOR SEGUE OS SEGUINTES PASSOS:',
  'retcart.confirm.outvio.text4': '1. Imprime a Folha de Devolução e recorta a etiqueta de envio.',
  'retcart.confirm.outvio.text5':
    '2. Coloca a Folha de Devolução dentro da embalagem com os produtos e cola a etiqueta de envio no exterior da caixa.',
  'retcart.confirm.outvio.text6':
    '3. Senta-te, relaxa e aguarda que a transportadora recolha a tua devolução!',
  'retcart.inst.headline.important': 'IMPORTANTE:',
  'retcart.inst.hasPickup.noLabel': `1. Confirma o endereço de recolha e os produtos a devolver{lineBreak}
      2. Quando clicares "CONFIRMAR", a Folha de Devolução será criada.{lineBreak}
      3. Coloca os artigos a devolver na embalagem e fecha-a bem. {fillerOptionally}, podes colocar também a Folha de Devolução dentro da caixa.{lineBreak}
      4. Assim que clicares Confirmar, a transportadora será notificada e irá recolher a tua devolução em até 2 dias úteis. IMPORTANTE! Não é necessário imprimir a etiqueta de envio, a transportadora irá levá-la consigo quando for fazer a recolha`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Opcionalmente',
  'retcart.inst.hasPickup.byEmail': `1. Confirma o endereço de recolha e os produtos a devolver.{lineBreak}
      2. Quando clicares "CONFIRMAR", a Folha de Devolução será criada e a transportadora receberá o pedido de recolha. {fillerNotice}.{lineBreak}
      3. Assim que recebere a Etiqueta de Envio, coloca a Folha de Devolução dentro da embalagem com os produtos a devolver, fecha-a bem e cola a Etiqueta de Envio no exterior.{lineBreak}
      4. A nossa transportadora irá recolher a tua devolução nos próximos 2 dias úteis.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Vais receber a Etiqueta de Envio no email ({email}) nos próximos 2 dias úteis.',
  'retcart.inst.hasPickup.provided': `1. Confirma o endereço de recolha e os produtos a devolver.{lineBreak}
      2. Quando clicares "CONFIRMAR", a Folha de Devolução será criada. Por favor imprime-a e recorta as Etiquetas de Envio para cada uma das embalagens com os produtos a devolver.{lineBreak}
      3. Coloca os produtos a devolver dentro da embalagem juntamente com a Folha de Devolução, fecha a caixa e cola a etiqueta de envio no exterior.{lineBreak}
      4. A transportadora irá recolher a tua devolução dentro de 2 dias úteis.`,
  'retcart.inst.noPickup.byEmail': `1. Confirma os produtos a devolver{lineBreak}
      2. Quando clicares "CONFIRMAR", a Folha de Devolução será criada. {fillerNotice}{lineBreak}
      3. Vais receber a Etiqueta de Envio da devolução por email. Assim que a receberes, coloca a Folha de Devolução dentro da embalagem, fecha-a e cola a Etiqueta de Envio no exterior.{lineBreak}
      4. Entrega as embalagens com os produtos a devolver num dos pontos de recolha da tua transportadora nos próximos 5 dias úteis. Para encontrares o ponto de recolha mais próximo visita a página da transportadora {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Vais receber a Etiqueta de Envio no email ({email}) dentro de 2 dias úteis.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'aqui',
  'retcart.inst.noPickup.provided': `1. Confirma os produtos a devolver{lineBreak}
      2. Quando clicares "CONFIRMAR", a Folha de Devolução será criada. Por favor imprime-a e recorta as etiquetas de envio para cada uma das embalagens que fores enviar.{lineBreak}
      3. Coloca os produtos a devolver dentro da embalagem juntamente com a Folha de Devolução, fecha-a bem e cola a Etiqueta de Envio no exterior.{lineBreak}
      4. Entrega as embalagens com os produtos a devolver num dos pontos de recolha da tua transportadora nos próximos 5 dias. Para encontrares o ponto de recolha mais próximo visita a página da transportadora {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'aqui',
  'retcart.confirm.outvio.head-sheet':
    'A TUA DEVOLUÇÃO FOI PROCESSADA COM SUCESSO, CLICA NO BOTÃO ABAIXO PARA TRANSFERIRES A FOLHA DE DEVOLUÇÃO',
  'retcart.confirm.outvio.head-sheet.note':
    '(a Folha de Devolução foi enviada também para o teu email):',
  'retcart.confirm.outvio.head-label':
    'A TUA DEVOLUÇÃO FOI PROCESSADA COM SUCESSO, CLICA NO BOTÃO ABAIXO PARA TRANSFERIRES A TUA ETIQUETA DE ENVIO',
  'retcart.confirm.outvio.head-label.note': '(a etiqueta foi enviada para o teu email também):',
  'retcart.confirm.outvio.head-label-by-email': 'A TUA DEVOLUÇÃO FOI PROCESSADA COM SUCESSO',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'PARA GARANTIR QUE CORRE TUDO COMO PLANEADO QUANDO A TUA TRANSPORTADORA CHEGAR, POR FAVOR SEGUE OS SEGUINTES PASSOS:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Imprime a Folha de Devolução (opcional).{lineBreak}
      2. Coloca a folha de Devolução dentro da embalagem junto com os produtos a devolver. NÃO É NECESSÁRIO ETIQUETA DE ENVIO!{lineBreak}
      3. A transportadora irá recolher a tua devolução nos próximos 2 dias úteis e levará a Etiqueta de Envio para a tua devolução.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: A ETIQUETA DE ENVIO SERÁ ENVIADA PARA O TEU EMAIL NOS PRÓXIMOS DIAS.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Assim que receberes a Etiqueta de Envio no teu email, imprime-a juntamente com a Folha de Devolução.{lineBreak}
      2. Coloca a Folha de Devolução dentro da embalagem, fecha-a bem e cola a Etiqueta de Envio no exterior.{lineBreak}
      3. A transportadora irá recolher a tua devolução nos próximos 2 dias úteis.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Imprime a Folha de Devolução e recorta a etiqueta de envio.{lineBreak}
      2. Coloca a Folha de Devolução dentro da embalagem, fecha-a bem e cola a etiqueta de envio no exterior.{lineBreak}
      3. A transportadora irá recolher a tua devolução dentro de 2 dias úteis.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Assim que receberes a Etiqueta de Envio por email, imprime-a juntamente com a Folha de Devolução.{lineBreak}
      2. Coloca a Folha de Devolução dentro da embalagem, fecha-a bem e cola a Etiqueta de Envio no exterior.{lineBreak}
      3. Entrega a embalagem com os produtos a devolver num dos pontos de recolha da tua transportadora. Para encontrares o ponto de recolha mais próximo, visita a página da tua transportadora {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'aqui',
  'retcart.confirm.outvio.noPickup.provided': `1. Imprime a Folha de Devolução e recorta a etiqueta de envio.{lineBreak}
      2. Coloca a Folha de Devolução dentro da embalagem, fecha-a bem e cola a Etiqueta de Envio no exterior.{lineBreak}
      3. Entrega a tua embalagem num dos pontos de recolha da tua transportadora. Para encontrares o ponto de recolha mais próximo, visita a página da tua transportadora {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'aqui',
  'general.retcart.restart.btn': 'Recomeçar o processo de devolução',
  'general.heading.refund.methods': 'COMO DESEJAS RECEBER O REEMBOLSO?',
  'retcart.method.SAME_AS_PAYMENT': 'REEMBOLSO PARA O MÉTODO DE PAGAMENTO ORIGINAL',
  'retcart.method.CREDIT_IN_STORE': 'CRÉDITO EM LOJA PARA COMPRAS FUTURAS',
  'retcart.confirm.exchange.heading': 'PEDIDO DE TROCA CONCLUÍDO',
  'retcart.confirm.exchange.text1': 'O TEU PEDIDO DE TROCA FOI RECEBIDO COM SUCESSO.',
  'retcart.confirm.exchange.text2': '(Foi enviada uma cópia do pedido de troca para o teu email).',
  'retcart.confirm.exchange.text3':
    'Entraremos em contacto brevemente para te informar dos detalhes da tua troca.',
  'retcart.confirm.exchange.text4': 'OBRIGADO!',
  'general.cancel': 'cancelar',
  'general.retcart.init-restart': 'Se o problema persistir, recomeça o processo clicando AQUI',
  'retcart.return-method.toClientShop': 'EM LOJA',
  'retcart.return-method.outvio': 'A NOSSA TRANSPORTADORA',
  'retcart.return-method.ownCourier': 'TRANSPORTADORA PRÓPRIA',
  'retcart.return-method.exchange': 'TROCA / SUBSTITUIÇÃO',
  'retcart.return-method.SAME_AS_PAYMENT': 'REEMBOLSO',
  'retcart.return-method.CREDIT_IN_STORE': 'CRÉDITO EM LOJA',
  'retcart.return-method.pickup': 'RECOLHA',
  'retcart.return-method.dropoff': 'ENTREGA',
  'general.heading.pickup.methods':
    'QUERES SER TU A ENTREGAR A TUA DEVOLUÇÃO OU PREFERES QUE A TRANSPORTADORA A VÁ RECOLHER?',
  'retcart.method.pickup': 'QUERO QUE A TRANSPORTADORA VENHA RECOLHER A DEVOLUÇÃO NA MINHA MORADA',
  'retcart.method.dropoff': 'QUERO SER EU A ENTREGAR A DEVOLUÇÃO À TRANSPORTADORA',
  'retcart.img-attach-desc': 'Carrega fotografias da embalagem, do produto e da etiqueta de envio',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'IMPORTANTE! ANTES DE ENTREGARES A TUA DEVOLUÇÃO NUM PONTO DE RECOLHA, POR FAVOR:',
  'retcart.title.pickup': 'recolha',
  'retcart.title.dropoff': 'entrega',
  'general.tracking.manage-exception': 'Gerir exceção no website da transportadora',
  'general.error.id-ver-token-missing':
    'Autenticação necessária. Por favor verifica o email de autenticação que te enviamos.',
  'general.error.id-ver.token-missing':
    'Erro a iniciar. Por favor confirma que estás a utilizar um navegador perene atualizado.',
  'general.error.fileupload.file-too-large': 'Ficheiros com dimensão máxima de {size} MB',
  'general.error.fileupload.too-many-files': 'Máximo de {number} ficheiro(s)',
  'general.error.fileupload.file-invalid-type': 'Tipo de ficheiro inválido',
  'general.error.fileupload.incorrect.size.max':
    'A resolução da imagem deve ser de, no máximo, {width} por {height}',
};
