import React, { VFC, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { DisplayStep, OutvioStatusCodes, StepStatus } from '../../../../../Interfaces';

/**
 * sl = short line
 * ar = arrow head
 * md = substep line
 */

const Dot: VFC<{ step: DisplayStep }> = ({ step }) => {
  const { status, isSubStep, code, isLastDoneStep } = step;

  const backgroundStyle = useMemo(() => {
    /**
     * Substeps don't have dots
     */
    if (isSubStep) return '';
    switch (status) {
      case StepStatus.DONE:
      case StepStatus.INCIDENT:
        return 'bg-tp-btnBg';
      case StepStatus.BACKTRACK_INCIDENT:
      case StepStatus.BACKTRACK:
        return '';
      case StepStatus.PENDING:
      default:
        return 'bg-tp-btnBg opacity-20';
    }
  }, [status, isSubStep]);

  const isDelshop = useMemo<boolean>(() => {
    return code === OutvioStatusCodes.DELIVERED_TO_SHOP;
  }, [code]);

  return (
    <div
      className={twMerge(
        'm-auto right-0 left-0 absolute z-10 rounded-full flex items-center justify-center bg-tp-secondaryBg',
        isDelshop ? 'bottom-auto top-[18px]' : 'bottom-0 top-0',
        isLastDoneStep ? 'w-[8px] h-[8px]' : 'w-[14px] h-[14px]',
        (status === StepStatus.BACKTRACK_INCIDENT || status === StepStatus.BACKTRACK) &&
          'w-[8px] h-[8px] bg-tp-secondaryBg outline outline-2 outline-tp-primary ',
      )}
    >
      <div className={twMerge('w-[8px] h-[8px] rounded-full', backgroundStyle)} />
    </div>
  );
};

export default Dot;
