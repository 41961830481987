export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'ATPAKAĻ',
  'general.required': 'Obligāts',
  'general.PRODUCT_PACKAGE_BROKEN': 'Preces iepakojums bojāts',
  'general.PRODUCT_BROKEN': 'Prece bojāta',
  'general.PRODUCT_USED': 'Prece lietota',
  'general.PRODUCT_DIRTY': 'Prece netīra',
  'general.MISSING_PRODUCT_LABEL': 'Trūkst preces etiķetes',
  'general.PRODUCT_MISSING': 'Trūkst preces',
  'general.OTHER': 'Cits',
  'general.credit-card': 'Kredītkarte',
  'general.bank-transfer': 'Bankas pārskaitījums',
  'general.cash-on-delivery': 'Samaksa, preci saņemot',
  'general.continue': 'Turpināt',
  'general.CLOSED': 'PABEIGTS',
  'general.PARTIALLY_RECEIVED': 'SAŅEMTS DAĻĒJI',
  'general.RECEIVED': 'SAŅEMTS',
  'general.IN_TRANSIT': 'CEĻĀ',
  'general.comments': 'Komentāri',
  'general.return': 'Atgriezt',
  'general.user-account': 'Lietotāja konts',
  'general.sign-out': 'Izrakstīties',
  'general.accept': 'Pieņemt',
  'general.was-returned-on': 'Prece tika atgriezta',
  'general.product-cannot-returned': 'Šo preci nevar atgriezt',
  'general.product-non-returnable':
    'Šo preci nevar atgriezt. Lūdzu, sazinieties ar klientu atbalsta dienestu, ja vēlaties to atgriezt',
  'general.can-return-it': 'Jūs varat to atgriezt līdz',
  'general.created': 'IZVEIDOTS',
  'general.shipped': 'NOSŪTĪTS',
  'general.in-transit': 'CEĻĀ',
  'general.out-of-delivery': 'NODOTS PIEGĀDEI',
  'general.delivered': 'PIEGĀDĀTS',
  'general.shipping-charges': 'PIEGĀDES MAKSA',
  'general.order-details': 'Pasūtījuma detaļas',
  'general.return-details': 'Atgriešanas detaļas',
  'general.no-orders-in-transit': 'Pašreiz ceļā nav neviena pasūtījuma',
  'general.products-returned': 'Preces, kas jāatgriež',
  'general.select-motive': 'Izvēlieties iemeslu',
  'general.why-want-return': 'Kāpēc vēlaties atgriezt šo preci?',
  'general.do-have-comments': 'Vai jums ir kādi komentāri?',
  'general.enter-comment': 'Ierakstiet savu komentāru',
  'general.do-want-attach-image': 'Vai vēlaties pievienot kādu attēlu?',
  'general.pickup-address': 'Saņemšanas adrese',
  'general.date-time-pickup': 'Saņemšanas datums un laiks',
  'general.contact-phone-number': 'Kontakttālruņa numurs',
  'general.comments-courier': 'Komentārs kurjeram',
  'general.confirm-address': 'Es apstiprinu paņemšanas adresi:',
  'general.quantity': 'Daudzums',
  'general.length': 'Garums',
  'general.width': 'Platums',
  'general.height': 'Augstums',
  'general.identifier': 'Identifikators',
  'general.tracking-number': 'Izsekošanas numurs',
  'general.date-purchase': 'Pirkuma datums',
  'general.date-time': 'Datums laiks',
  'general.status': 'Statuss',
  'general.shipping-cost': 'Piegādes maksa šai atgriešanai',
  'general.free': 'Bez maksas',
  'general.done': 'Gatavs',
  'general.spanish': 'Spāņu',
  'general.english': 'Angļu',
  'returns.step.products.title': 'PREČU ATLASE',
  'returns.step.products.text': 'Atzīmējiet preces, kuras vēlaties atgriezt',
  'returns.step.method.title': 'ATGRIEŠANAS METODE',
  'returns.step.method.text': 'Izvēlieties atgriešanas metodi',
  'returns.step.instructions.title': 'INSTRUKCIJAS',
  'returns.step.instructions.text': 'Sagatavojiet preces atgriešanu',
  'returns.step.confirm.title': 'APSTIPRINĀJUMS',
  'returns.step.confirm.text': 'Atgriešana pabeigta',
  'general.img-upload': 'Velciet un nometiet vai noklikšķiniet, lai pievienotu attēlu',
  'retcart.packages.text': `Iepakojiet savas preces, lai pabeigtu preču atgriešanu
    {lineBreak}{lineBreak}MŪSU PADOMI:
    {lineBreak}- Mēģiniet atkārtoti izmantot to iepakojumu, kas tika lietots jūsu pasūtījuma piegādei. Tas ir labi mūsu planētai, un tas ir viegli pieejams!
    {lineBreak}- Ja atgriežat vairāk nekā vienu preci, mēģiniet izmantot pēc iespējas mazāk paku.
    {lineBreak}- Atcerieties, ka, nosūtot preces, tiek nosūtīts arī gaiss iepakojuma iekšpusē! Ja iespējams un ja tas ir droši, mēģiniet izmantot paku, kas samazinātu tukšo vietu. Tas samazinās arī jūsu preču atgriešanas izmaksas.`,
  'retcart.step2.heading':
    'APSTIPRINIET SAVU IEPAKOJUMA IZMĒRU (NOKLIKŠĶINIET UZ ‘PIEVIENOT PAKU’, JA JUMS NEPIECIEŠAMA VAIRĀK KĀ VIENA)',
  'retcart.pack.default-title.display': 'IZMANTOT PIEGĀDES IEPAKOJUMU:',
  'retcart.pack.custom-title.display': 'IZMANTOT PIELĀGOTU PAKU',
  'retcart.pack.custom-title.edit': 'IZMANTOT PIELĀGOTU PAKU (izmērs cm)',
  'retcart.pack.change-size': '(Mainīt izmēru)',
  'retcart.pack.remove': '(Noņemt)',
  'retcart.pack.add': 'PIEVIENOT PAKU',
  'retcart.addrmodal.heading1': 'IZVĒLIETIES KĀDU NO JŪSU SAGLABĀTAJĀM ADRESĒM',
  'retcart.addrmodal.heading2': 'VAI IEVADIET JAUNU ADRESI',
  'retcart.print.loading.heading': 'Mēs apstrādājam jūsu preču atgriešanu...',
  'retcart.print.loading.note': 'Neaizveriet šo logu, līdz esam apstrādājuši jūsu pieprasījumu!',
  'landing.email-sent':
    'E-pasts ir veiksmīgi nosūtīts. Lūdzu, pārbaudiet savu iesūtni, lai verificētu e-pastu nākamo 15 minūšu laikā.',
  'general.status.IN_TRANSIT': 'CEĻĀ',
  'general.status.DELIVERED': 'PIEGĀDĀTS',
  'general.status.DELIVERED_POST_OFFICE': 'PIEGĀDĀTS',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'PIEGĀDĀTS UZ{lineBreak}PIEŅEMŠANAS PUNKTU',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'DAĻĒJI PIEGĀDĀTS',
  'general.status.PARTIALLY_DELIVERED.short': 'DAĻ.PIEGĀDĀTS',
  'general.status.DELAYED': 'KAVĒJAS',
  'general.status.RETURNED': 'ATGRIEZTS',
  'general.status.DELETED': 'DZĒSTS',
  'general.status.RECEIVED': 'SAŅEMTS',
  'general.status.PARTIALLY_RECEIVED': 'DAĻĒJI SAŅEMTS',
  'general.status.PARTIALLY_RECEIVED.short': 'DAĻ.SAŅEMTS',
  'general.status.CLOSED': 'AIZVĒRTS',
  'general.status.CREATED': 'IZVEIDOTS',
  'general.status.PICKING_QUEUE': 'SAŅEMŠANAS RINDA',
  'general.status.SHIPPING_QUEUE': 'PIEGĀDES RINDA',
  'general.status.REFUNDED': 'ATGRIEZTA NAUDA',
  'general.status.SHIPMENT_PICKUP': 'SŪTĪJUMA SAŅEMŠANA',
  'general.status.SHIPPED': 'NOSŪTĪTS',
  'general.status.PICKUP': 'NOSŪTĪTS',
  'general.status.IN_TRANSIT_DELAYED': 'KAVĒJAS CEĻĀ',
  'general.status.EXCEPTION': 'IZŅĒMUMS',
  'general.status.OUT_FOR_DELIVERY': 'NODOTS PIEGĀDEI',
  'general.status.RETURNED_TO_ORIGIN': 'ATGRIEZTS SĀKUMPUNKTĀ',
  'general.status.CANCELLED': 'ATCELTS',
  'sidebar.orders-in-transit': 'PASŪTĪJUMI CEĻĀ',
  'sidebar.my-orders': 'MANI PASŪTĪJUMI',
  'sidebar.returns': 'ATGRIEZTĀS PRECES',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'Ārējais kurjers',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Iekšējais',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Datums Laiks',
  'general.trackingbar.location': 'Atrašanās vieta',
  'general.trackingbar.status': 'Statuss',
  'general.trackingbar.detail': 'Detaļas',
  'general.trackingbar.no-info': 'Informācija vēl nav pieejama.',
  'general.return-error.all-returned': 'Visas preces ir atgrieztas.',
  'retcart.heading.methods': 'KĀ JŪS VĒLATIES ATGRIEZT SAVAS PRECES?',
  'retcart.method.toClientShop': 'NOGĀDĀT PRECES UZ VIENU NO MŪSU VEIKALIEM JUMS TUVUMĀ',
  'retcart.method.outvio': 'IZMANTOT MŪSU KURJERU, LAI NOSŪTĪTU PRECES MUMS ATPAKAĻ',
  'retcart.method.ownCourier': 'NOSŪTĪT MUMS ATPAKAĻ PRECES, IZMANTOJOT SAVU KURJERU',
  'retcart.method.exchange': 'APMAINĪT VAI AIZSTĀT PRECES, NEVIS NOSŪTĪT TĀS ATPAKAĻ',
  'retcart.inst.toClientShop.heading':
    'APSTIPRINIET PREČU ATGRIEŠANU UN NOGĀDĀJIET PRECES UZ VIENU NO MŪSU VEIKALIEM JUMS TUVUMĀ',
  'retcart.inst.toClientShop.text1':
    'LAI PABEIGTU PREČU ATGRIEŠANU, LŪDZU, noklikšķiniet uz APSTIPRINĀT pogas zemāk:',
  'retcart.inst.toClientShop.text2':
    'Jums tiks izveidota Atgriešanas lapa, un jums būs jānogādā preces uz kādu no mūsu veikaliem pirms {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Pilnu mūsu veikalu sarakstu varat atrast šeit: {link}.',
  'retcart.inst.toClientShop.text3.link': 'veikalu atrašanās vietu saraksts',
  'general.confirm': 'apstiprināt',
  'retcart.inst.ownCourier.heading':
    'APSTIPRINIET PREČU ATGRIEŠANU UN NOSŪTIET MUMS ATPAKAĻ PRECES, IZMANTOJOT KURJERU PĒC SAVAS IZVĒLES',
  'retcart.inst.ownCourier.text1':
    'LAI PABEIGTU JŪSU PREČU ATGRIEŠANU, LŪDZU, noklikšķiniet uz APSTIPRINĀT pogas zemāk:',
  'retcart.inst.ownCourier.text2':
    'Jums tiks izveidota Atgriešanas lapa, un jums būs jānogādā mums preces pirms {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Jums būs jānosūta preces uz:',
  'retcart.inst.ownCourier.text4':
    'P.S.: Jūs varat izmantot jebkuru sev tīkamu kurjeru, un jums būs jāmaksā preču atgriešanas izmaksas. Mēs iesakām izmantot uzticamu izsekošanas pakalpojumu, lai pārliecinātos, ka jūsu atgriežamās preces nonāk līdz mums.',
  'retcart.inst.exchange.heading': 'SNIEDZIET MUMS PAPILDU INFORMĀCIJU UN APSTIPRINIET SAVU MAIŅU',
  'retcart.inst.exchange.text1':
    'Ar kuru preci, izmēru un daudzumu vēlaties apmainīt pašreizējo(-ās) preci(-es)?',
  'retcart.inst.exchange.text2':
    'Pārliecinieties, ka vēstulē esat iekļāvis visu nepieciešamo informāciju.',
  'retcart.inst.exchange.text.placeholder': 'Norādiet savu preces maiņas informāciju šeit.',
  'retcart.inst.exchange.text3': `Lai pabeigtu preču maiņu, lūdzu, noklikšķiniet uz TURPINĀT pogas zemāk.',
  
Mums tiks nosūtīts e-pasts ar jūsu pieprasījumu par preču maiņu, un mēs sazināsimies ar jums, lai vienotos par maiņas detaļām. E-pasta ziņojuma kopija tiks nosūtīta arī uz jūsu e-pasta adresi.`,
  'retcart.confirm-return-terms': 'Esmu pārskatījis savu preču atgriešanu un piekrītu {terms}',
  'retcart.confirm-return-terms.terms': 'Noteikumi un nosacījumi',
  'retcart.confirm.heading': 'ATGRIEŠANA PABEIGTA',
  'retcart.confirm.text3':
    'LAI PĀRLIECINĀTOS, KA SAISTĪBĀ AR JŪSU PREČU ATGRIEŠANU VISS NORIT GLUDI:',
  'retcart.confirm.toClientShop.text4':
    '1. Izdrukājiet Atgriešanas lapu vai saglabājiet elektronisko kopiju, kuru esam nosūtījuši uz jūsu e-pastu.',
  'retcart.confirm.toClientShop.text5': '2. Nogādājiet preces uz {fillerShop} pirms {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'jums tuvumā esošu veikalu',
  'retcart.confirm.toClientShop.text6': '3. Noteikti paņemiet līdzi savu Atgriešanas lapu.',
  'retcart.download-return-sheet': 'LEJUPIELĀDĒT ATGRIEŠANAS LAPU',
  'retcart.download-label': 'LEJUPIELĀDĒJIET ETIĶETI UN ATGRIEŠANAS LAPU',
  'retcart.confirm.ownCourier.text1':
    '1. Izdrukājiet Atgriešanas lapu un ievietojiet to iepakojumā kopā ar precēm, kuras atgriežat.',
  'retcart.confirm.ownCourier.text2':
    '2. Nosūtiet savas atgriežamās preces ar uzticamu izsekojamu kurjeru pēc savas izvēles uz adresi:',
  'retcart.confirm.ownCourier.text3':
    '3. Pārliecinieties, ka esat nosūtījis atpakaļ preces pirms {dueDate}.',
  'retcart.confirm.outvio.text1':
    'JŪSU PREČU ATGRIEŠANA IR VEIKSMĪGI APSTRĀDĀTA, LŪDZU, LEJUPIELĀDĒJIET SAVU PIEGĀDES ETIĶETI, NOKLIKŠĶINOT UZ ZEMĀK REDZAMĀS POGAS',
  'retcart.confirm.outvio.text2': '(etiķete ir nosūtīta arī uz jūsu e-pastu):',
  'retcart.confirm.outvio.text3':
    'LAI PĀRLIECINĀTOS, KA VISS NORIT GLUDI, KAD IERODAS KURJERS, LŪDZU:',
  'retcart.confirm.outvio.text4': '1. Izdrukājiet Atgriešanas lapu un izgrieziet piegādes etiķeti.',
  'retcart.confirm.outvio.text5':
    '2. Ievietojiet Atgriešanas lapu iepakojuma iekšpusē un uzlīmējiet etiķeti uz rūpīgi aizlīmētas pakas.',
  'retcart.confirm.outvio.text6':
    '3. Sēdiet, atpūtieties un gaidiet, kad kurjers paņems jūsu atgriežamās preces!',
  'retcart.inst.headline.important': 'SVARĪGI:',
  'retcart.inst.hasPickup.noLabel': `1. Pārskatiet savu saņemšanas adresi un atgriežamās preces.{lineBreak}
  2. Pēc "APSTIPRINĀT" nospiešanas tiks izveidota Atgriešanas lapa.{lineBreak}
  3. Ievietojiet atgriežamās preces iepakojumā un aizlīmējiet paku. {fillerOptionally}, jūs pakā varat ievietot arī Atgriešanas lapu.{lineBreak}
  4. Tiklīdz kā noklikšķināsiet uz "Apstiprināt", mēs par to paziņosim kurjeram, un viņš nākamo 2 darba dienu laikā ieradīsies pie jums, lai paņemtu atgriežamās preces. SVARĪGI! Jums nav jāizdrukā piegādes etiķete. Kurjers to atvedīs, kad brauks paņemt jūsu atgriežamās preces.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Pēc izvēles',
  'retcart.inst.hasPickup.byEmail': `1. Pārskatiet savu saņemšanas adresi un atgriežamās preces.{lineBreak}
      2. Pēc "APSTIPRINĀT" nospiešanas tiks izveidota Atgriešanas lapa, un mūsu kurjeram tiks nosūtīts preču saņemšanas pieprasījums. {fillerNotice}.{lineBreak}
      3. Kad esat saņēmis Piegādes etiķeti, ievietojiet Atgriešanas lapu iepakojumā, aizveriet to un uzlīmējiet piegādes etiķeti uz pakas.{lineBreak}
      4. Mūsu kurjers nākamo 2 darba dienu laikā ieradīsies pie jums, lai paņemtu atgriežamās preces.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Piegādes etiķeti jūs saņemsiet pa e-pastu ({email}) nākamo 2 darba dienu laikā.',
  'retcart.inst.hasPickup.provided': `1. Pārskatiet savu saņemšanas adresi un atgriežamās preces.{lineBreak}
  2. Pēc "APSTIPRINĀT" nospiešanas tiks izveidota Atgriešanas lapa. Lūdzu, izdrukājiet to un izgrieziet piegādes etiķeti katrai pakai ar atgriežamajām precēm.{lineBreak}
  3. Ievietojiet atgriežamās preces iepakojumā kopā ar Atgriešanas lapu, uzlīmējiet piegādes etiķeti un aizlīmējiet kasti.{lineBreak}
  4. Kurjers nākamo 2 darba dienu laikā ieradīsies pie jums, lai paņemtu atgriežamās preces.`,
  'retcart.inst.noPickup.byEmail': `1. Pārskatiet produktus savā atgriešanas sūtījumā. {lineBreak}
      2. Pēc "APSTIPRINĀT" nospiešanas tiks izveidota Atgriešanas lapa. {fillerNotice}{lineBreak}
      3. Jūs saņemsiet atgriežamo preču piegādes etiķeti pa e-pastu. Kad esat saņēmis Piegādes etiķeti, ievietojiet Atgriešanas lapu iepakojumā, aizlīmējiet to un uzlīmējiet piegādes etiķeti uz pakas.{lineBreak}
      4. Jums būs jānogādā paka(s) uz kurjera pieņemšanas punktu netālu no jums 5 darba dienu laikā. Lai atrastu sev vistuvāko pieņemšanas punktu, lūdzu, apmeklējiet kurjera mājaslapu {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Jūs saņemsiet Piegādes etiķeti uz e-pastu ({email}) nākamo 2 dienu laikā.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'šeit',
  'retcart.inst.noPickup.provided': `1. Pārskatiet produktus savā atgriešanas sūtījumā. {lineBreak}
      2. Pēc "APSTIPRINĀT" nospiešanas tiks izveidota Atgriešanas lapa. Lūdzu, to izdrukājiet un izgrieziet piegādes etiķeti katrai pakai ar atgriežamajām precēm.{lineBreak}
      3. Ievietojiet atgriežamās preces kopā ar Atgriešanas lapu, uzlīmējiet piegādes etiķeti un aizlīmējiet kasti.{lineBreak}
      4. Jums būs jānogādā paka(s) uz kurjera pieņemšanas punktu netālu no jums. Lai atrastu sev vistuvāko pieņemšanas punktu, lūdzu, apmeklējiet kurjera mājaslapu {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'šeit',
  'retcart.confirm.outvio.head-sheet':
    'JŪSU PREČU ATGRIEŠANA IR VEIKSMĪGI APSTRĀDĀTA. LŪDZU, LEJUPIELĀDĒJIET SAVU ATGRIEŠANAS LAPU, NOKLIKŠĶINOT UZ ZEMĀK REDZAMĀS POGAS',
  'retcart.confirm.outvio.head-sheet.note': '(Atgriešanas lapa ir nosūtīta arī uz jūsu e-pastu):',
  'retcart.confirm.outvio.head-label':
    'JŪSU PREČU ATGRIEŠANA IR VEIKSMĪGI APSTRĀDĀTA. LŪDZU, LEJUPIELĀDĒJIET SAVU PIEGĀDES ETIĶETI, NOKLIKŠĶINOT UZ ZEMĀK REDZAMĀS POGAS',
  'retcart.confirm.outvio.head-label.note': '(etiķete ir nosūtīta arī uz jūsu e-pastu):',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'LAI PĀRLIECINĀTOS, KA VISS NORIT GLUDI, KAD IERODAS KURJERS, LŪDZU:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Izdrukājiet Atgriešanas lapu (pēc izvēles).{lineBreak}
  2. Ievietojiet Atgriešanas lapu droši aizlīmētā iepakojumā. PIEGĀDES ETIĶETE NAV VAJADZĪGA!{lineBreak}
  3. Kurjers nākamo 2 darba dienu laikā ieradīsies paņemt jūsu atgriežamās preces un atvedīs piegādes etiķeti jūsu atgriežamajām precēm.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'P.S.: PIEGĀDES ETIĶETE NĀKAMO DIENU LAIKĀ TIKS NOSŪTĪTA UZ JŪSU E-PASTU.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Kad esat saņēmis Piegādes etiķeti uz savu e-pastu, izdrukājiet to kopā ar Atgriešanas lapu.{lineBreak}
      2. Ievietojiet Atgriešanas lapu iepakojuma iekšpusē, aizlīmējiet to un uzlīmējiet uz pakas piegādes etiķeti.{lineBreak}
      3. Kurjers nākamo 2 darba dienu laikā ieradīsies paņemt jūsu atgriežamās preces.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Izdrukājiet Atgriešanas lapu un izgrieziet piegādes etiķeti.{lineBreak}
      2. Ievietojiet Atgriešanas lapu iepakojuma iekšpusē, aizlīmējiet to un uzlīmējiet uz pakas piegādes etiķeti.{lineBreak}
      3. Kurjers nākamo 2 darba dienu laikā ieradīsies paņemt jūsu atgriežamās preces.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Kad esat saņēmis Piegādes etiķeti uz savu e-pastu, izdrukājiet to kopā ar Atgriešanas lapu.{lineBreak}
      2. Ievietojiet Atgriešanas lapu iepakojuma iekšpusē, aizlīmējiet to un uzlīmējiet uz pakas piegādes etiķeti.{lineBreak}
      3. Nogādājiet paku uz sev tuvāko kurjera pieņemšanas punktu. Lai atrastu sev vistuvāko pieņemšanas punktu, lūdzu, apmeklējiet kurjera mājaslapu {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'šeit',
  'retcart.confirm.outvio.noPickup.provided': `1. Izdrukājiet Atgriešanas lapu un izgrieziet piegādes etiķeti.{lineBreak}
      2. Ievietojiet Atgriešanas lapu iepakojuma iekšpusē, aizlīmējiet to un uzlīmējiet uz pakas piegādes etiķeti.{lineBreak}
      3. Nogādājiet paku uz sev tuvāko kurjera pieņemšanas punktu. Lai atrastu sev vistuvāko pieņemšanas punktu, lūdzu, apmeklējiet kurjera mājaslapu {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'šeit',
  'general.retcart.restart.btn': 'Atsākt atgriešanas procesu',
  'general.heading.refund.methods': 'KĀ VĒLATIES SAŅEMT ATPAKAĻ SAVU SAMAKSĀTO NAUDU?',
  'retcart.method.SAME_AS_PAYMENT': 'ATGRIEZT NAUDU UZ MANU SĀKOTNĒJO MAKSĀJUMA METODI',
  'retcart.method.CREDIT_IN_STORE': 'VEIKALA KREDĪTS NĀKOTNES PIRKUMIEM',
  'retcart.confirm.exchange.heading': 'MAIŅAS PIEPRASĪJUMS PABEIGTS',
  'retcart.confirm.exchange.text1': 'JŪSU MAIŅAS PIEPRASĪJUMS IR VEIKSMĪGI SAŅEMTS.',
  'retcart.confirm.exchange.text2':
    '(Jūsu maiņas pieprasījuma kopija ir nosūtīta arī uz jūsu e-pasta adresi).',
  'retcart.confirm.exchange.text3':
    'Mēs drīzumā ar jums sazināsimies, lai darītu jums zināmas detaļas saistībā ar jūsu preču maiņu.',
  'retcart.confirm.exchange.text4': 'PALDIES!',
  'general.cancel': 'atcelt',
  'general.retcart.init-restart':
    'Ja šī problēma turpina pastāvēt, lūdzu, atsāciet procesu, noklikšķinot ŠEIT',
  'retcart.return-method.toClientShop': 'VEIKALĀ',
  'retcart.return-method.outvio': 'MŪSU KURJERS',
  'retcart.return-method.ownCourier': 'JŪSU KURJERS',
  'retcart.return-method.exchange': 'MAIŅA / AIZSTĀŠANA',
  'retcart.return-method.SAME_AS_PAYMENT': 'NAUDAS ATMAKSA',
  'retcart.return-method.CREDIT_IN_STORE': 'VEIKALA KREDĪTS',
  'retcart.return-method.pickup': 'PAŅEMŠANA',
  'retcart.return-method.dropoff': 'NODOŠANA',
  'general.heading.pickup.methods':
    'VAI VĒLATIES PATS ATVEST SAVU PAKU VAI GRIBAT, LAI KURJERS TO PAŅEM NO JUMS?',
  'retcart.method.pickup': 'ES VĒLOS, LAI KURJERS PAŅEM ATGRIEŽAMĀS PRECES NO MANĀM MĀJĀM',
  'retcart.method.dropoff': 'ES PATS VĒLOS AIZVEST PAKU LĪDZ KURJERA PIEŅEMŠANAS PUNKTAM',
  'retcart.img-attach-desc': 'Augšupielādējiet iepakojuma, preces un piegādes etiķetes attēlus',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'SVARĪGI! PIRMS VEDAT ATGRIEŽAMĀS PRECES UZ PIEŅEMŠANAS PUNKTU, LŪDZU:',
  'retcart.title.pickup': 'paņemšana',
  'retcart.title.dropoff': 'nodošana',
  'general.tracking.manage-exception': 'Pārvaldīt izņēmumus kurjera mājaslapā',
  'general.error.id-ver-token-missing':
    'Marķieris ir nepieciešams autentifikācijai. Lūdzu, pārbaudiet nosūtīto e-pasta ziņojumu autentifikācijai.',
  'general.error.id-ver.token-missing':
    'Inicializēšanas kļūda. Lūdzu, pārliecinieties, ka izmantojat automātiski atjauninātu pārlūkprogrammu.',
  'general.error.fileupload.file-too-large': 'Maksimālais faila lielums {size} MB',
  'general.error.fileupload.too-many-files': 'Maksimāli {number} fails(i)',
  'general.error.fileupload.file-invalid-type': 'Nederīgs faila tips',
  'general.error.fileupload.incorrect.size.max':
    'Attēla izšķirtspējai jābūt ne lielākai kā {width} reiz {height}',
};
