import React, { FC, useContext, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { Star } from 'icons';
import { OVCButton, OVCStoreDataContext, getCalculatedBrightnessFromHex } from 'return-portal-ui';
import { Heading } from 'ui/atoms';

import TrackingPageContext from '../../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../../Interfaces';
import { Emojis } from '../../../Interfaces/ITrackingData';
import BaseModal from '../../Common/BaseModal';
import EditButton from '../../Common/EditButton';
import RatingInput from '../../Common/Inputs/RatingInput';

interface RatingModal {
  isRatingModalVisible: boolean;
  toggleRatingModal(value: boolean): void;
  handleRate?(rating: number): void;
}

const ratingValues: { value: number; emoji: Emojis }[] = [
  { value: 1, emoji: Emojis.SAD },
  { value: 2, emoji: Emojis.MKAY },
  { value: 3, emoji: Emojis.HAPPY },
  { value: 4, emoji: Emojis.BIG_SMILE },
  { value: 5, emoji: Emojis.STARRY_EYES },
];

const initialValues: { rating?: number } = {
  rating: undefined,
};

const RatingModal: FC<RatingModal> = (props) => {
  const {
    portalSettings: {
      regionalSettings: {
        rating: { rateButton, askLater, thanks, body },
      },
    },
    appSettings: { isEdit },
  } = useContext(TrackingPageContext);

  const {
    storeData: { portalV2 },
  } = useContext(OVCStoreDataContext);

  const { isRatingModalVisible: isVisible, toggleRatingModal: setIsVisible, handleRate } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (values: { rating?: number }): Promise<boolean> => {
    setIsSubmitting(true);
    return new Promise((resolve) => {
      if (handleRate && values.rating) handleRate(values.rating);
      setTimeout(() => resolve(true), 1000);
    });
  };

  const inputBgL1 = getCalculatedBrightnessFromHex(portalV2?.colors?.secondaryBg || '', 1);

  return (
    <BaseModal
      isVisible={isVisible}
      handleClose={() => setIsVisible(false)}
      withTitleBar={false}
      iframeHeight="0px"
      customStyle={{ padding: 24, maxHeight: 'min-content', position: 'relative' }}
    >
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values).finally(() => setIsVisible(false))}
        >
          {({ dirty, values }) => (
            <Form className="flex flex-col space-y-8 md:w-max h-min">
              {!isSubmitting && (
                <>
                  <Heading type="heading2" className="px-2 text-tp-primaryText text-center">
                    {body}
                  </Heading>
                  <div
                    style={{ borderColor: inputBgL1 }}
                    className="flex px-6 h-[64px] items-center mx-auto space-x-2 border rounded-[18px]"
                  >
                    {ratingValues.map(({ value, emoji }) => {
                      const color =
                        (values?.rating || 0) < value ? inputBgL1 : portalV2?.colors?.btnBg;

                      return (
                        <Field
                          name="rating"
                          component={RatingInput}
                          emoji={<Star style={{ color }} className="text-[40px]" />}
                          val={value}
                          key={`${emoji}-${value}`}
                          selectedColor={inputBgL1}
                        />
                      );
                    })}
                  </div>
                  <div className="flex space-x-4 mx-auto">
                    <OVCButton
                      theme="secondary"
                      type="button"
                      className="pl-4 !text-tp-primaryText"
                      onClick={() => setIsVisible(false)}
                    >
                      {askLater}
                    </OVCButton>

                    <OVCButton
                      type="submit"
                      className="pl-4"
                      disabled={!dirty}
                      onClick={() => setIsVisible(false)}
                    >
                      {rateButton}
                    </OVCButton>
                  </div>
                </>
              )}
              {isSubmitting && <span>{thanks}</span>}
            </Form>
          )}
        </Formik>

        {isEdit && <EditButton tab={EDIT_MODE_TABS.RATING} />}
      </>
    </BaseModal>
  );
};

export default RatingModal;
