export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'TAKAISIN',
  'general.required': 'Vaaditaan',
  'general.PRODUCT_PACKAGE_BROKEN': 'Tuotteen pakkaus rikki',
  'general.PRODUCT_BROKEN': 'Tuote rikki',
  'general.PRODUCT_USED': 'Tuote käytetty',
  'general.PRODUCT_DIRTY': 'Tuote likainen',
  'general.MISSING_PRODUCT_LABEL': 'Tuotteen lappu puuttuu',
  'general.PRODUCT_MISSING': 'Tuote puuttuu',
  'general.OTHER': 'Muu',
  'general.credit-card': 'Luottokortti',
  'general.bank-transfer': 'Tilisiirto',
  'general.cash-on-delivery': 'Käteinen toimituksen yhteydessä',
  'general.continue': 'Jatka',
  'general.CLOSED': 'SULJETTU',
  'general.PARTIALLY_RECEIVED': 'OSITTAIN VASTAANOTETTU',
  'general.RECEIVED': 'VASTAANOTETTU',
  'general.IN_TRANSIT': 'KULJETUKSESSA',
  'general.comments': 'Kommentit',
  'general.return': 'Palautus',
  'general.user-account': 'Käyttäjätili',
  'general.sign-out': 'Kirjaudu ulos',
  'general.accept': 'Hyväksy',
  'general.was-returned-on': 'Se palautettiin',
  'general.product-cannot-returned': 'Tätä tuotetta ei voi palauttaa',
  'general.product-non-returnable':
    'Tätä tuotetta ei voi palauttaa. Ota yhteyttä asiakaspalveluun, jos haluat palauttaa sen',
  'general.can-return-it': 'Viimeinen palautuspäivä',
  'general.created': 'LUOTU',
  'general.shipped': 'LÄHETETTY',
  'general.in-transit': 'KULJETUKSESSA',
  'general.out-of-delivery': 'TOIMITETAAN',
  'general.delivered': 'TOIMITETTU',
  'general.shipping-charges': 'TOIMITUSKULUT',
  'general.order-details': 'Tilauksen tiedot',
  'general.return-details': 'Palautuksen tiedot',
  'general.no-orders-in-transit': 'Tilauksia ei ole tällä hetkellä toimitettavana',
  'general.products-returned': 'Palautettavat tuotteet',
  'general.select-motive': 'Valitse syy',
  'general.why-want-return': 'Miksi haluat palauttaa tämän tuotteen?',
  'general.do-have-comments': 'Onko sinulla kommentoitavaa?',
  'general.enter-comment': 'Lisää kommenttisi',
  'general.do-want-attach-image': 'Haluatko liittää kuvia?',
  'general.pickup-address': 'Nouto-osoite',
  'general.date-time-pickup': 'Noutopäivä ja -aika',
  'general.contact-phone-number': 'Puhelinnumero',
  'general.comments-courier': 'Kommentti kuriirille',
  'general.confirm-address': 'Vahvistan nouto-osoitteen:',
  'general.quantity': 'Määrä',
  'general.length': 'Pituus',
  'general.width': 'Leveys',
  'general.height': 'Korkeus',
  'general.identifier': 'Tunniste',
  'general.tracking-number': 'Seurantanumero',
  'general.date-purchase': 'Ostopäivä',
  'general.date-time': 'Päivä ja aika',
  'general.status': 'Tila',
  'general.shipping-cost': 'Tämän palautuksen toimituskulut',
  'general.free': 'Ilmainen',
  'general.done': 'Valmis',
  'general.spanish': 'Espanja',
  'general.english': 'Englanti',
  'returns.step.products.title': 'TUOTTEEN VALINTA',
  'returns.step.products.text': 'Valitse palautettavat tuotteet',
  'returns.step.method.title': 'PALAUTUSTAPA',
  'returns.step.method.text': 'Valitse palautustapa',
  'returns.step.instructions.title': 'OHJEET',
  'returns.step.instructions.text': 'Valmistele palautuksesi',
  'returns.step.confirm.title': 'VAHVISTUS',
  'returns.step.confirm.text': 'Palautus suoritettu',
  'general.img-upload': 'Raahaa ja pudota tai napsauta lisätäksesi kuvan',
  'retcart.packages.text': `Pakkaa tuotteet palautusta varten
    {lineBreak}{lineBreak}VINKKEJÄ:
    {lineBreak}- Yritä käyttää samaa pakettia, jossa tuotteen sait. Se on jo helposti käytettävissä ja ympäristöystävällinen valinta!
    {lineBreak}- Jos palautat useamman kuin yhden tuotteen, yritä käyttää mahdollisimman vähän eri paketteja.
    {lineBreak}- Muista, että lähettämäsi paketin sisältämä ilma lähetetään myös! Jos mahdollista ja turvallista, käytä mahdollisimman vähän tyhjää tilaa sisältävää pakettia. Se myös vähentää palautuskulujasi.`,
  'retcart.step2.heading':
    'VAHVISTA PAKKAUKSESI KOKO (NAPSAUTA "LISÄÄ PAKETTI", JOS SINUN TARVITSEE LÄHETTÄÄ USEAMPI)',
  'retcart.pack.default-title.display': 'KÄYTÄ TOIMITUSPAKETTIA:',
  'retcart.pack.custom-title.display': 'KÄYTÄ OMAA PAKETTIA',
  'retcart.pack.custom-title.edit': 'KÄYTÄ OMAA PAKETTIA (koko cm)',
  'retcart.pack.change-size': '(Muuta kokoa)',
  'retcart.pack.remove': '(Poista)',
  'retcart.pack.add': 'LISÄÄ PAKETTI',
  'retcart.addrmodal.heading1': 'VALITSE JOKIN TALLENNETUISTA OSOITTEISTA',
  'retcart.addrmodal.heading2': 'TAI SYÖTÄ UUSI OSOITE',
  'retcart.print.loading.heading': 'Käsittelemme palautustasi...',
  'retcart.print.loading.note': 'Älä sulje tätä ikkunaa, ennen kuin olemme käsitelleet pyyntösi!',
  'landing.email-sent':
    'Sähköposti lähetetty onnistuneesti, tarkistathan sähköpostisi varmentaaksesi sen seuraavien 15 minuutin aikana.',
  'general.status.IN_TRANSIT': 'KULJETUKSESSA',
  'general.status.DELIVERED': 'TOIMITETTU',
  'general.status.DELIVERED_POST_OFFICE': 'TOIMITETTU',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'TOIMITETTU{lineBreak}NOUTOPAIKKAAN',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'TOIMITETTU OSITTAIN',
  'general.status.PARTIALLY_DELIVERED.short': 'TOIMITETTU OSA.',
  'general.status.DELAYED': 'VIIVÄSTYNYT',
  'general.status.RETURNED': 'PALAUTETTU',
  'general.status.DELETED': 'POISTETTU',
  'general.status.RECEIVED': 'VASTAANOTETTU',
  'general.status.PARTIALLY_RECEIVED': 'OSITTAIN VASTAANOTETTU',
  'general.status.PARTIALLY_RECEIVED.short': 'VASTAANOTETTU OSA.',
  'general.status.CLOSED': 'SULJETTU',
  'general.status.CREATED': 'LUOTU',
  'general.status.PICKING_QUEUE': 'NOUTOJONO',
  'general.status.SHIPPING_QUEUE': 'TOIMITUSJONO',
  'general.status.REFUNDED': 'HYVITETTY',
  'general.status.SHIPMENT_PICKUP': 'TOIMITUKSEN NOUTO',
  'general.status.SHIPPED': 'LÄHETETTY',
  'general.status.PICKUP': 'LÄHETETTY',
  'general.status.IN_TRANSIT_DELAYED': 'KULJETUKSESSA, VIIVÄSTYNYT',
  'general.status.EXCEPTION': 'POIKKEUS',
  'general.status.OUT_FOR_DELIVERY': 'TOIMITETAAN',
  'general.status.RETURNED_TO_ORIGIN': 'PALAUTETTU LÄHETTÄJÄLLE',
  'general.status.CANCELLED': 'PERUTTU',
  'sidebar.orders-in-transit': 'KULJETUKSESSA OLEVAT TILAUKSET',
  'sidebar.my-orders': 'TILAUKSENI',
  'sidebar.returns': 'PALAUTUKSET',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'Ulkoinen kuriiri',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'Kauppaan',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Päivä ja aika',
  'general.trackingbar.location': 'Sijainti',
  'general.trackingbar.status': 'Tila',
  'general.trackingbar.detail': 'Merkinnät',
  'general.trackingbar.no-info': 'Tietoa ei ole vielä saatavilla.',
  'general.return-error.all-returned': 'Kaikki tuotteet on palautettu.',
  'retcart.heading.methods': 'KUINKA HALUAT PALAUTTAA TUOTTEESI?',
  'retcart.method.toClientShop': 'VIE TUOTTEET YHTEEN KAUPOISTAMME',
  'retcart.method.outvio': 'KÄYTÄ KURIIRIPALVELUAMME PALAUTTAAKSESI TUOTTEET MEILLE',
  'retcart.method.ownCourier': 'PALAUTA TUOTE OMAA KURIIRIPALVELUASI KÄYTTÄEN',
  'retcart.method.exchange': 'VAIHDA TAI KORVAA TUOTE PALAUTTAMISEN SIJAAN',
  'retcart.inst.toClientShop.heading': 'VAHVISTA PALAUTUKSESI JA VIE TUOTTEET LÄHEISEEN KAUPPAAN',
  'retcart.inst.toClientShop.text1':
    'VIIMEISTELLÄKSESI PALAUTUKSESI napsauta alla olevaa VAHVISTA-painiketta:',
  'retcart.inst.toClientShop.text2':
    'Sinulle luodaan palautuslista ja sinun tulee viedä tuotteet johonkin kaupoistamme viimeistään {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Löydät listan kaikista kaupoistamme täällä: {link}.',
  'retcart.inst.toClientShop.text3.link': 'lista kauppojen sijainneista',
  'general.confirm': 'vahvista',
  'retcart.inst.ownCourier.heading':
    'VAHVISTA PALAUTUKSESI JA LÄHETÄ TUOTTEET VALITSEMALLASI KURIIRIPALVELULLA',
  'retcart.inst.ownCourier.text1':
    'VIIMEISTELLÄKSESI PALAUTUKSESI napsauta alla olevaa VAHVISTA-painiketta:',
  'retcart.inst.ownCourier.text2':
    'Sinulle luodaan palautuslista ja sinun tulee lähettää tuotteet viimeistään {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Sinun tulee lähettää tuotteet osoitteeseen:',
  'retcart.inst.ownCourier.text4':
    'P.S.: Voit käyttää mitä tahansa kuriiria, mutta sinun tulee maksaa palautuskulut. Suosittelemme käyttämään luotettavaa, seurantatunnuksella tarjottua palvelua, jotta palautus saapuu meille varmasti.',
  'retcart.inst.exchange.heading': 'KERRO MEILLE TIEDOT JA VAHVISTA VAIHTOSI',
  'retcart.inst.exchange.text1':
    'Millä tuotteella, koolla ja määrällä haluat vaihtaa nykyisen tuotteen/nykyiset tuotteet?',
  'retcart.inst.exchange.text2': 'Muista lisätä viestiisi kaikki tarvittavat tiedot.',
  'retcart.inst.exchange.text.placeholder': 'Jätä meille tähän vaihtoa koskevat tiedot.',
  'retcart.inst.exchange.text3': `Viimeistelläksesi vaihdon, napsauta alla olevaa JATKA-painiketta.',
  
Saamme sähköpostin vaihtopyyntöäsi koskien ja otamme sinuun sen jälkeen yhteyttä sopiaksemme vaihdosta. Viestistä lähetetään sinulle myös kopio sähköpostitse.`,
  'retcart.confirm-return-terms': 'Olen tarkistanut palautukseni ja hyväksyn {terms}',
  'retcart.confirm-return-terms.terms': 'Ehdot',
  'retcart.confirm.heading': 'PALAUTUS SUORITETTU',
  'retcart.confirm.text3': 'VARMISTAAKSESI, ETTÄ KAIKKI SUJUU HYVIN PALAUTUKSESI SUHTEEN:',
  'retcart.confirm.toClientShop.text4':
    '1. Tulosta palautuslista tai tallenna sähköinen kopio, jonka lähetimme sähköpostiisi.',
  'retcart.confirm.toClientShop.text5': '2. Vie tuote {fillerShop} viimeistään {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'lähelläsi olevaan kauppaan',
  'retcart.confirm.toClientShop.text6': '3. Muista tuoda palautuslista mukanasi.',
  'retcart.download-return-sheet': 'LATAA PALAUTUSLISTA',
  'retcart.download-label': 'LATAA LAPPU JA PALAUTUSLOMAKE',
  'retcart.confirm.ownCourier.text1':
    '1. Tulosta palautuslista ja laita se pakettiin palauttamiesi tuotteiden mukaan.',
  'retcart.confirm.ownCourier.text2':
    '2. Lähetä palautus valitsemaasi luotettavaa, seurantatunnuksen tarjoavaa kuriiripalvelua käyttäen osoitteeseen:',
  'retcart.confirm.ownCourier.text3': '3. Muista lähettää palautus viimeistään {dueDate}.',
  'retcart.confirm.outvio.text1':
    'PALAUTUKSESI ON KÄSITELTY ONNISTUNEESTI, LATAA OSOITELAPPU NAPSAUTTAMALLA ALLA OLEVAA PAINIKETTA',
  'retcart.confirm.outvio.text2': '(lappu on lisäksi lähetetty sinulle sähköpostitse):',
  'retcart.confirm.outvio.text3': 'VARMISTAAKSESI, ETTÄ KAIKKI SUJUU HYVIN KURIIRIN SAAPUESSA:',
  'retcart.confirm.outvio.text4': '1. Tulosta palautuslista ja leikkaa osoitelappu irti siitä.',
  'retcart.confirm.outvio.text5':
    '2. Aseta palautuslista pakkauksen sisään ja kiinnitä osoitelappu huolella suljettuun pakettiin.',
  'retcart.confirm.outvio.text6':
    '3. Voit ottaa rennosti, kunnes kuriiri tulee noutamaan palautuksesi!',
  'retcart.inst.headline.important': 'TÄRKEÄÄ:',
  'retcart.inst.hasPickup.noLabel': `1. Tarkista nouto-osoite ja palautukseen lukeutuvat tuotteet.{lineBreak}
    2. Kun olet napsauttanut "VAHVISTA", sinulle luodaan palautuslomake.{lineBreak}
    3. Aseta palautettavat tuotteet pakkauksen sisälle ja sulje se. {fillerOptionally}, voit myös laittaa palautuslomakkeen laatikon sisään.{lineBreak}
    4. Kun olet napsauttanut vahvistuspainiketta, ilmoitamme kuriirille, joka tulee noutamaan palautuksen seuraavien kahden arkipäivän kuluessa. TÄRKEÄÄ! Sinun ei tarvitse tulostaa toimituslappua. Kuriiri tuo sen noutaessaan palautuksen.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Vaihtoehtoisesti',
  'retcart.inst.hasPickup.byEmail': `1. Tarkista nouto-osoitteesi ja palauttamasi tuotteet.{lineBreak}
      2. Kun olet napsauttanut "VAHVISTA", sinulle luodaan palautuslista ja noutopyyntösi lähetetään kuriirillemme. {fillerNotice}.{lineBreak}
      3. Kun olet saanut osoitelappusi, laita palautuslista paketin sisään, sulje paketti ja kiinnitä osoitelappu pakettiin.{lineBreak}
      4. Kuriirimme tulee noutamaan palautuksesi seuraavien kahden arkipäivän sisällä.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Saat toimituslapun sähköpostitse ({email}) kahden seuraavan arkipäivän kuluessa.',
  'retcart.inst.hasPickup.provided': `1. Tarkista nouto-osoite ja palautukseen lukeutuvat tuotteet.{lineBreak}
    2. Kun olet napsauttanut "VAHVISTA", sinulle luodaan palautuslomake. Tulosta se ja leikkaa irti toimituslappu jokaiselle palautuksesi paketille.{lineBreak}
    3. Aseta palautettavat tuotteet pakkauksen sisälle palautuslomakkeen kera, kiinnitä toimituslappu pakettiin ja sulje se.{lineBreak}
    4. Kuriiri tulee noutamaan palautuksesi kahden seuraavan arkipäivän kuluessa.`,
  'retcart.inst.noPickup.byEmail': `1. Tarkista palautuksesi tuotteet. {lineBreak}
      2. Kun olet napsauttanut "VAHVISTA", sinulle luodaan palautuslista. {fillerNotice}{lineBreak}
      3. Sinulle lähetetään osoitelappu sähköpostitse. Kun olet saanut sen, aseta palautuslista pakkauksen sisälle, sulje pakkaus ja kiinnitä osoitelappu pakettiin.{lineBreak}
      4. Sinun tulee viedä paketti/paketit kuriirin noutopisteeseen seuraavien viiden arkipäivän sisällä. Löydät sinua lähimmät noutopisteet kuriirin sivustolta {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Sinulle lähetetään osoitelappu sähköpostitse osoitteeseen ({email}) seuraavien kahden päivän sisällä.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'täällä',
  'retcart.inst.noPickup.provided': `1. Tarkista palautuksesi tuotteet. {lineBreak}
      2. Kun olet napsauttanut "VAHVISTA", sinulle luodaan palautuslista. Tulosta se ja leikkaa siitä irti osoitelappu kullekin palauttamallesi paketille.{lineBreak}
      3. Aseta palauttamasi tuotteet paketin sisään yhdessä palautuslistan kanssa, kiinnitä osoitelappu pakettiin ja sulje paketti.{lineBreak}
      4. Sinun tulee viedä paketti/paketit kuriirin noutopisteeseen. Löydät sinua lähimmät noutopisteet kuriirin sivustolta {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'täällä',
  'retcart.confirm.outvio.head-sheet':
    'PALAUTUKSESI ON KÄSITELTY ONNISTUNEESTI, LATAA PALAUTUSLISTA NAPSAUTTAMALLA ALLA OLEVAA PAINIKETTA',
  'retcart.confirm.outvio.head-sheet.note':
    '(Palautuslista on lähetetty sinulle myös sähköpostitse):',
  'retcart.confirm.outvio.head-label':
    'PALAUTUKSESI ON KÄSITELTY ONNISTUNEESTI, LATAA OSOITELAPPU NAPSAUTTAMALLA ALLA OLEVAA PAINIKETTA',
  'retcart.confirm.outvio.head-label.note': '(Lappu on lähetetty sinulle myös sähköpostitse):',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'VARMISTAAKSESI, ETTÄ KAIKKI SUJUU HYVIN KURIIRIN SAAPUESSA:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Tulosta palautuslomake (vaihtoehtoinen).{lineBreak}
    2. Laita palautuslomake huolella suljettuun pakettiin. ET TARVITSE TOIMITUSTIETOJA!{lineBreak}
    3. Kuriiri tulee noutamaan palautuksesi kahden seuraavan arkipäivän kuluessa ja tuo mukanaan toimituslapun palautuksellesi.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'P.S.: OSOITELAPPU LÄHETETÄÄN SINULLE SÄHKÖPOSTITSE MUUTAMAN PÄIVÄN SISÄLLÄ.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Kun olet saanut osoitelapun sähköpostitse, tulosta se yhdessä palautuslistan kanssa.{lineBreak}
      2. Aseta palautuslista paketin sisään, sulje paketti ja kiinnitä osoitelappu siihen.{lineBreak}
      3. Kuriiri tulee noutamaan palautuksesi seuraavien kahden arkipäivän sisällä.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Tulosta palautuslista ja leikkaa osoitelappu siitä irti.{lineBreak}
      2. Aseta palautuslista paketin sisään, sulje paketti ja kiinnitä osoitelappu siihen.{lineBreak}
      3. Kuriiri tulee noutamaan palautuksesi seuraavien kahden arkipäivän sisällä.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Kun olet saanut osoitelapun sähköpostitse, tulosta se yhdessä palautuslistan kanssa.{lineBreak}
      2. Aseta palautuslista paketin sisään, sulje paketti ja kiinnitä osoitelappu siihen.{lineBreak}
      3. Vie paketti kuriirin noutopisteeseen. Löydät sinua lähimmät noutopisteet kuriirin sivustolta {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'täällä',
  'retcart.confirm.outvio.noPickup.provided': `1. Tulosta palautuslista ja leikkaa osoitelappu siitä irti.{lineBreak}
      2. Aseta palautuslista paketin sisään, sulje paketti ja kiinnitä osoitelappu siihen.{lineBreak}
      3. Vie paketti kuriirin noutopisteeseen. Löydät sinua lähimmät noutopisteet kuriirin sivustolta {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'täällä',
  'general.retcart.restart.btn': 'Aloita palautusprosessi uudelleen',
  'general.heading.refund.methods': 'KUINKA HALUAT VASTAANOTTAA HYVITYKSEN?',
  'retcart.method.SAME_AS_PAYMENT': 'HYVITÄ ALKUPERÄISTÄ MAKSUTAPAA KÄYTTÄEN',
  'retcart.method.CREDIT_IN_STORE': 'KAUPAN KREDIITTINÄ TULEVIA OSTOKSIA VARTEN',
  'retcart.confirm.exchange.heading': 'VAIHTOPYYNTÖ SUORITETTU',
  'retcart.confirm.exchange.text1': 'VAIHTOPYYNTÖSI ON VASTAANOTETTU ONNISTUNEESTI.',
  'retcart.confirm.exchange.text2':
    '(Kopio vaihtopyynnöstäsi on lähetetty sinulle myös sähköpostitse).',
  'retcart.confirm.exchange.text3': 'Otamme sinuun yhteyttä pian vaihdon yksityiskohtia koskien.',
  'retcart.confirm.exchange.text4': 'KIITOS!',
  'general.cancel': 'peruuta',
  'general.retcart.init-restart':
    'Jos ongelma jatkuu, aloita prosessi uudelleen napsauttamalla TÄSTÄ',
  'retcart.return-method.toClientShop': 'KAUPPAAN',
  'retcart.return-method.outvio': 'KURIIRIMME',
  'retcart.return-method.ownCourier': 'OMA KURIIRI',
  'retcart.return-method.exchange': 'VAIHDA/KORVAA',
  'retcart.return-method.SAME_AS_PAYMENT': 'HYVITÄ',
  'retcart.return-method.CREDIT_IN_STORE': 'KAUPAN KREDIITTI',
  'retcart.return-method.pickup': 'NOUTO',
  'retcart.return-method.dropoff': 'TUO PAKETTI',
  'general.heading.pickup.methods': 'HALUATKO TUODA PAKETTISI VAI HALUATKO KURIIRIN NOUTAVAN SEN?',
  'retcart.method.pickup': 'HALUAN KURIIRIN NOUTAVAN PALAUTUKSEN KOTOANI',
  'retcart.method.dropoff': 'HALUAN VIEDÄ PAKETIN KURIIRIN NOUTOPISTEESEEN ITSE',
  'retcart.img-attach-desc': 'Lataa kuvia pakkauksesta, tuotteesta ja osoitelapusta',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'TÄRKEÄÄ! ENNEN KUIN TUOT PALAUTUKSEN PALAUTUSPISTEESEEN:',
  'retcart.title.pickup': 'nouto',
  'retcart.title.dropoff': 'tuonti',
  'general.tracking.manage-exception': 'Hallinnoi poikkeusta kuriirin sivustolla',
  'general.error.id-ver-token-missing':
    'Varmennus vaatii valtuutuksen. Tarkistathan varmennusta varten lähetetyn sähköpostin.',
  'general.error.id-ver.token-missing':
    'Käynnistysvirhe. Varmistathan, että käytät automaattisesti päivittyvää selainta ja että sinulla on sen tuorein versio.',
  'general.error.fileupload.file-too-large': 'Tiedoston maksimikoko on {size} MB',
  'general.error.fileupload.too-many-files': 'Enintään {number} tiedosto(a)',
  'general.error.fileupload.file-invalid-type': 'Virheellinen tiedostotyyppi',
  'general.error.fileupload.incorrect.size.max':
    'Kuvan resoluution tulee olla korkeintaan {width} kertaa {height}',
};
