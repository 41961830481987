import { memo } from 'react';

import SubscriptionFlow, { SubscriptionFlowProps } from './SubscriptionFlow';
import { SubscriptionContext } from './hooks/useSubscriptionContext';

type PricingBlockerWindowProps = SubscriptionFlowProps & { isOpen: boolean };

function PricingBlockerWindowBase({
  plans,
  subscription,
  updateSubscriptionInfo,
  hideCancel,
  type,
  planProgress,
  onProgressFinish,
  isOpen,
  shopifyProgress,
}: PricingBlockerWindowProps) {
  return (
    <>
      {isOpen && (
        <>
          <SubscriptionContext.Provider
            value={{ subscription, plans, planProgress, onProgressFinish, shopifyProgress }}
          >
            <SubscriptionFlow
              onProgressFinish={onProgressFinish}
              subscription={subscription}
              updateSubscriptionInfo={updateSubscriptionInfo}
              hideCancel={hideCancel}
              type={type}
            />
          </SubscriptionContext.Provider>
        </>
      )}
    </>
  );
}

export const PricingBlockerWindow = memo(PricingBlockerWindowBase);
