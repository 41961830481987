import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { StoreCurrency } from 'app-types/core';
import {
  GET_ALL_PAYMENT_METHODS_KEY,
  GET_ESTIMATED_SUB_BILL_KEY,
  useGetDeskInfo,
} from 'data-layer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import queryClient from 'tracking-fe/src/Stories/queryClient';
import { match } from 'ts-pattern';

import { getFromLocalStoragePlanInfo } from '../../../../organisms';
import { PricingPlanProps } from '../types/pricingTypes';
import { SubscriptionContext } from './useSubscriptionContext';

dayjs.extend(utc);

const usePricingBlocker = (updateSubscriptionInfo: () => void) => {
  const [step, setStep] = useState<PriceStep>(PriceStep.Price);

  const contextData = useContext(SubscriptionContext);
  const { subscription, plans } = contextData!;

  const { hasActivatedDesk } = useGetDeskInfo(subscription);
  const hasActivePlan = subscription?.isActive;

  const activePricingPlan: PricingPlanProps = {
    isDeskIncluded: hasActivePlan ? hasActivatedDesk : false,
    plan: subscription?.plan,
    nextPlan: subscription?.nextPlan,
    nextBillingDate: subscription?.nextRebill,
    isActive: subscription?.isActive,
    currency: (subscription?.currency as StoreCurrency) || StoreCurrency.Eur,
  };

  const [selectedPlan, setSelectedPlan] = useState<PricingPlanProps>(activePricingPlan);

  const isOnTrial = useMemo(
    () => (subscription.endPromo ? dayjs.utc(subscription.endPromo).isAfter() : false),
    [subscription],
  );

  const handleResetChanges = useCallback(() => {
    setSelectedPlan(activePricingPlan);
  }, [activePricingPlan]);

  const handleChangePlan = useCallback(
    (newSelectedPlan: PricingPlanProps) => {
      setSelectedPlan((state) => ({
        ...state,
        ...newSelectedPlan,
      }));

      const actualPlanPrice =
        plans.find((plan) => plan.name === activePricingPlan.plan)?.price || 0;
      const targetPlanPrice = plans.find((plan) => plan.name === newSelectedPlan.plan)?.price || 0;

      match<boolean>(true)
        .with(isOnTrial, () => setStep(PriceStep.Payment))
        .with(actualPlanPrice > targetPlanPrice && subscription.isActive, () =>
          setStep(PriceStep.DowngradePlan),
        )
        .otherwise(() => setStep(PriceStep.Payment));
    },
    [subscription],
  );

  const handleChangeDesk = useCallback(
    (plan: PricingPlanProps) => {
      setSelectedPlan(plan);

      if (activePricingPlan.isActive) {
        if (plan.isDeskIncluded) {
          setStep(PriceStep.PaymentDesk);
        } else {
          setStep(PriceStep.DowngradeDesk);
        }
      }
    },
    [subscription],
  );

  useEffect(() => {
    if (step === PriceStep.Cancel) {
      setSelectedPlan((state) => ({ ...state, plan: undefined, isDeskIncluded: false }));
      setStep(PriceStep.DowngradePlan);
    }
  }, [step]);

  useEffect(() => {
    const hasPaymentPlanInfo = getFromLocalStoragePlanInfo();

    if (hasPaymentPlanInfo && !!contextData?.planProgress?.cardActivated) {
      setStep(PriceStep.Payment);
    }
  }, [contextData?.planProgress?.cardActivated]);

  useEffect(() => {
    if (contextData?.planProgress?.upgradeFinished && !contextData?.planProgress?.error) {
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey[0] === GET_ESTIMATED_SUB_BILL_KEY;
        },
      });
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey[0] === GET_ALL_PAYMENT_METHODS_KEY;
        },
      });

      if (contextData?.onProgressFinish) {
        contextData?.onProgressFinish();
      }

      setStep(PriceStep.None);
    }
  }, [contextData?.planProgress?.upgradeFinished]);

  const handleUpdateSubInfo = useCallback(async () => {
    updateSubscriptionInfo();
  }, []);

  const handleSetStep = useCallback((newStep: PriceStep, updateSubscription?: boolean) => {
    updateSubscription && updateSubscriptionInfo();
    setStep(newStep);
  }, []);

  return {
    step,
    selectedPlan,
    activePricingPlan,
    handleSetStep,
    handleUpdateSubInfo,
    handleChangePlan,
    handleChangeDesk,
    handleResetChanges,
    isOnTrial,
  };
};

export enum PriceStep {
  None = 'None',
  Price = 'Price',
  Payment = 'Payment',
  PaymentDesk = 'PaymentDesk',
  Vat = 'Vat',
  DowngradePlan = 'DowngradePlan',
  DowngradeDesk = 'DowngradeDesk',
  RequestCall = 'RequestCall',
  RequestDemo = 'RequestDemo',
  Cancel = 'Cancel',
}

export { usePricingBlocker };
