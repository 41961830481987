import React, { useContext, useMemo } from 'react';

import { Theme } from 'react-select';
import Flag from 'react-world-flags';

import { makeStyles } from '@material-ui/core';
import { twMerge } from 'tailwind-merge';
import { DropdownButton, getColorContrast } from 'ui';
import { countryCodes } from 'ui/hooks';

import { CustomerSupportedLanguage2Letter, LanguageNames } from '../types/CustomerGeneral';
import { getCalculatedBrightnessFromHex } from '../utils';
import OVCLangSwitcherContext from './OVCLangSwitcherContext';
import OVCStoreDataContext from './OVCStoreDataContext';
import { LANG_TO_LOCALE } from './constants';

const FALLBACK_LANGUAGES: { name: CustomerSupportedLanguage2Letter }[] = [
  { name: 'en' },
  { name: 'es' },
  { name: 'et' },
];

type StyleProps = {
  buttonBgL1: string;
};

const useStyles = makeStyles<Theme, { buttonBgL1: string }>({
  buttonBg: {
    '&:hover': {
      backgroundColor: (props: StyleProps) => `${props.buttonBgL1} !important`,
    },
  },
});

const OVCLangSwitcher = () => {
  const { storeData } = useContext(OVCStoreDataContext);
  const langContext = useContext(OVCLangSwitcherContext);

  const buttonBgL1 = getCalculatedBrightnessFromHex(storeData.portalV2?.colors?.header || '', 1);
  const menuActiveL1 = getCalculatedBrightnessFromHex(
    storeData.portalV2?.colors?.secondaryBg || '',
    1,
  );
  const buttonTextColor = getColorContrast(storeData.portalV2?.colors.header || '', false);

  const classes = useStyles({
    buttonBgL1,
  });

  const langOptions = useMemo(
    () =>
      (storeData.languages.length > 0 ? storeData.languages : FALLBACK_LANGUAGES).map(
        (langOpt) => ({
          shortLocale: langOpt.name,
          langCode: LANG_TO_LOCALE[langOpt.name],
        }),
      ),
    [storeData],
  );

  return (
    <div className="md:absolute right-8 ml-6 md:ml-0">
      <DropdownButton
        isDoubleActionDisabled
        options={langOptions.map((langOpt) => {
          return {
            label: LanguageNames[langOpt.langCode],
            value: langOpt.langCode,
            onClick: () => langContext.changeLangTo(langOpt.langCode),
            icon: (
              <Flag
                className="rounded-sm w-[18px] h-[14px] object-cover mr-2"
                code={countryCodes[langOpt.shortLocale]}
              />
            ),
          };
        })}
        buttonClass={twMerge('!bg-transparent pl-6 pr-2', classes.buttonBg)}
        menuClass="!z-10"
        menuStyle={{
          backgroundColor: storeData.portalV2?.colors?.secondaryBg,
          boxShadow: '0 0 #0000',
          borderWidth: '1px',
          borderColor: menuActiveL1,
        }}
        iconclass="hidden"
        style={{ boxShadow: '0 0 #0000', color: buttonTextColor }}
        iconWrapperStyle={{ color: `${buttonTextColor}B3` }}
        position="bottom-end"
        menuItemActiveColor={menuActiveL1}
        menuItemClass="text-tp-primaryText"
      >
        <span>{LanguageNames[langContext.appLang]}</span>
      </DropdownButton>
    </div>
  );
};

export default OVCLangSwitcher;
