import React, { useContext } from 'react';

import { OVCStoreDataContext, getCalculatedBrightnessFromHex } from 'return-portal-ui';
import { twMerge } from 'tailwind-merge';

type CardProps = {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  hasBottomBorder?: boolean;
};

const Card = ({ children, className, wrapperClassName, hasBottomBorder = true }: CardProps) => {
  const {
    storeData: { portalV2 },
  } = useContext(OVCStoreDataContext);

  const borderColor = getCalculatedBrightnessFromHex(portalV2?.colors?.secondaryBg || '', 2);

  return (
    <>
      <div className="hidden md:block w-full">
        <Wrapper className={wrapperClassName} secondaryBg={portalV2?.colors?.secondaryBg || ''}>
          <Content
            className={className}
            borderColor={borderColor}
            hasBottomBorder={hasBottomBorder}
            secondaryBg={portalV2?.colors?.secondaryBg || ''}
          >
            {children}
          </Content>
        </Wrapper>
      </div>

      <div className="md:hidden w-full">
        <Content
          secondaryBg={portalV2?.colors?.secondaryBg || ''}
          className={className}
          borderColor={borderColor}
          hasBottomBorder={hasBottomBorder}
        >
          {children}
        </Content>
      </div>
    </>
  );
};

const Wrapper = ({
  children,
  secondaryBg,
  className,
}: {
  children: React.ReactNode;
  secondaryBg: string;
  className?: string;
}) => (
  <div
    className={twMerge('md:px-[1px] md:py-[1px] md:rounded-[14px] w-full h-fit', className)}
    style={{ backgroundColor: `${secondaryBg}4D` }}
  >
    {children}
  </div>
);

const Content = ({
  children,
  className,
  borderColor,
  hasBottomBorder,
  secondaryBg,
}: {
  children: React.ReactNode;
  className?: string;
  borderColor: string;
  hasBottomBorder: boolean;
  secondaryBg: string;
}) => (
  <div
    style={{ borderColor: borderColor, backgroundColor: secondaryBg || '' }}
    className={twMerge(
      'flex w-full relative md:rounded-[14px] ',
      hasBottomBorder ? 'border-b md:border-none' : '',
      className,
    )}
  >
    {children}
  </div>
);

export default Card;
