import React, { InputHTMLAttributes, useContext } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import { twMerge } from 'tailwind-merge';
import { getColorContrast } from 'ui';
import { TextInput } from 'ui/atoms';

import { getCalculatedBrightnessFromHex } from '../utils';
import OVCStoreDataContext from './OVCStoreDataContext';

export interface IOVCInput extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  useSecondaryBg?: boolean;
  inputClassName?: string;
}

type StyleProps = {
  inputBgL1: string;
  inputBgL2: string;
  inputBgL3: string;
  inputTextColor: string;
};

const useStyles = makeStyles<
  Theme,
  { inputBgL1: string; inputBgL2: string; inputBgL3: string; inputTextColor: string }
>({
  inputBackground: {
    backgroundColor: (props: StyleProps) => `${props.inputBgL1} !important`,
    color: (props: StyleProps) => `${props.inputTextColor} !important`,
    '&:hover': {
      backgroundColor: (props: StyleProps) => `${props.inputBgL2} !important`,
      borderWidth: '0px',
    },
    '&:focus': {
      backgroundColor: (props: StyleProps) => `${props.inputBgL1} !important`,
      outlineWidth: '1px',
      outlineOffset: '0px',
      borderWidth: '0px',
      outlineColor: (props: StyleProps) => `${props.inputBgL2} !important`,
    },
    '&::placeholder': {
      color: (props: StyleProps) => `${props.inputBgL3} !important`,
    },
    '&:disabled': {
      color: (props: StyleProps) => `${props.inputBgL3} !important`,
    },
  },
});

const OVCInput = ({ error = false, className = '', inputClassName = '', ...rest }: IOVCInput) => {
  const {
    storeData: { portalV2 },
  } = useContext(OVCStoreDataContext);

  const textColor = getColorContrast(portalV2?.colors?.secondaryBg || '', true);
  const inputBgL1 = getCalculatedBrightnessFromHex(portalV2?.colors?.secondaryBg || '', 1);
  const inputBgL2 = getCalculatedBrightnessFromHex(portalV2?.colors?.secondaryBg || '', 2);
  const inputBgL3 = getCalculatedBrightnessFromHex(portalV2?.colors?.secondaryBg || '', 3);

  const classes = useStyles({
    inputBgL1,
    inputBgL2,
    inputBgL3,
    inputTextColor: portalV2?.colors?.primaryText || '',
  });

  return (
    <TextInput
      isError={error}
      className={twMerge(className)}
      inputClassName={twMerge(
        'transition-all',

        classes.inputBackground,
        textColor,
        inputClassName,
      )}
      backgroundStyle={{ borderWidth: '0px' }}
      id={rest?.name}
      name={rest?.name || ''}
      {...rest}
    />
  );
};

export default OVCInput;
