import React, { forwardRef, useContext } from 'react';

import Card from 'tracking-fe/src/Components/Common/Card';
import { Heading } from 'ui/atoms';

import { useGetRegionalTranslationsV2 } from '../hooks';
import OVCStoreDataContext from './OVCStoreDataContext';

interface IOVCAuthBox {
  children: React.ReactNode;
  formWidth?: number;
}

const OVCAuthBox = forwardRef<HTMLDivElement, IOVCAuthBox>(({ children }, ref) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const regionalTranslations = useGetRegionalTranslationsV2();

  return (
    <div ref={ref} className="md:w-[400px] w-full py-8">
      <Card hasBottomBorder={false} className="flex flex-col pt-8 pb-6 px-6">
        <>
          <Heading
            style={{ color: storeData.portalV2?.colors.primaryText }}
            className="text-center"
            weight="semibold"
          >
            {regionalTranslations?.loginPageInfo?.titleTrackingPortal || ''}
          </Heading>
          <div className="flex flex-col items-center justify-center gap-[5px]">{children}</div>
        </>
      </Card>
    </div>
  );
});

export default OVCAuthBox;
